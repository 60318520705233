import { createRouter, createWebHashHistory } from 'vue-router';
import AdmLogin from './components/AdmLogin.vue';
import AdmBuildings from './components/AdmBuildings.vue';
import AdmCustomers from './components/AdmCustomers.vue';

import AdmProfile from './components/AdmProfile.vue';
import AdmUsers from './components/AdmUsers.vue';
import AdmDeleteObjects from './components/AdmDeleteObjects.vue';
import AdmDeleteCustomers from './components/AdmDeleteCustomers.vue';


//export default createRouter({

export const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        { path: '/login', name: 'login', component: AdmLogin },
        { path: '/buildings', name: 'buildings', component: AdmBuildings },
        { path: '/customers', name: 'customers', component: AdmCustomers },

        { path: '/profile', name: 'profile', component: AdmProfile },
        { path: '/users', name: 'users', component: AdmUsers },
        { path: '/delete-objects', name: 'delete-objects', component: AdmDeleteObjects },
        { path: '/delete-customers', name: 'delete-customers', component: AdmDeleteCustomers },
    ]
})


router.beforeEach( (to, from, next) => {
    const token =  localStorage.getItem('x_xsrf_token')

    if (!token) {
        if (to.name === 'login') {
            return next()
        } else {
            return next({
                name: 'login'
            })
        }
    }
    if (to.name === 'login' && token) {
        return next({
            name: 'buildings'
        })
    }

    next()

})


