<template>
    <div class="container-fluid">
        <div id="content">

            <div class="filter-list">
                <div class="filter-list-tabs">
                    <input class="inp-radiobox-btn" type="radio" id="actual_b"
                           v-model="this.$store.state.filterDataClient.filter_results"
                           :value=1
                           @change="getCustomers">
                    <label class="form-radiobox-label" for="actual_b">В роботі</label>

                    <input class="inp-radiobox-btn" type="radio"  id="all_b"
                           v-model="this.$store.state.filterDataClient.filter_results"
                           :value=2
                           @change="getCustomers">
                    <label class="form-radiobox-label" for="all_b">Всі</label>

                    <input class="inp-radiobox-btn" type="radio" id="filter_stage_favourite"
                           v-model="this.$store.state.filterDataClient.filter_results"
                           :value=3
                           @change="getCustomers">
                    <label class="form-radiobox-label" for="filter_stage_favourite">Улюблені</label>

                    <input class="inp-radiobox-btn" type="radio" id="filter_stage_adequate"
                           v-model="this.$store.state.filterDataClient.filter_results"
                           :value=4
                           @change="getCustomers">
                    <label class="form-radiobox-label" for="filter_stage_adequate">Адекватні</label>

                    <input class="inp-radiobox-btn" type="radio" id="filter_stage_dreamer"
                           v-model="this.$store.state.filterDataClient.filter_results"
                           :value=5
                           @change="getCustomers">
                    <label class="form-radiobox-label" for="filter_stage_dreamer">Мрійники</label>
                </div>
                <div class="item-count">( {{count}} )</div>
                <div class="filter-list-search">
                    <div class="filter_search_box">
                        <input type="text" class="form-control" id="filter_search"
                               v-model="$store.state.filterDataClient.filters.search"
                               v-on:keypress.enter="getCustomers"
                               placeholder="Пошук">
                        <label class="lbl-search-btn" for="filter_search"
                               @click="getCustomers">Пошук</label>
                    </div>
                </div>
            </div>


            <div class="object-list">
                <div class="object-list-head">


                    <div class="list-item--check"></div>
                    <div class="item-info">
                        <div class="list-item--upd">
                            <div class="sort">
                                <input class="inp-radiobox-btn" type="radio" id="sort_u"
                                       v-model="this.$store.state.filterDataClient.sort2"
                                       value="created_at"
                                       @click="setSortDirection">
                                <label class="form-radiobox-label" for="sort_u">Оновл<span>{{arrow}}</span></label>
                            </div>
                        </div>
                        <div class="list-item--stage">Етап</div>
                        <div class="list-item--phone">Телефон</div>
                        <div class="list-item--name">Ім'я</div>
                        <div class="list-item--room">Кім</div>

                        <div class="list-item--price">
                            <div class="sort">
                                <input class="inp-radiobox-btn" type="radio" id="sort_p"
                                       v-model="this.$store.state.filterDataClient.sort2"
                                       value="price"
                                       @click="setSortDirection">
                                <label class="form-radiobox-label" for="sort_p">Ціна<span>{{arrow}}</span></label>
                            </div>
                        </div>
                        <div class="list-item--districts">Район</div>
                        <div class="list-item--creat">
                            <div class="sort">
                                <input class="inp-radiobox-btn" type="radio" id="sort_c"
                                       v-model="this.$store.state.filterDataClient.sort2"
                                       value="updated_at"
                                       @click="setSortDirection">
                                <label class="form-radiobox-label" for="sort_c">Створ<span>{{arrow}}</span></label>
                            </div>
                        </div>
                        <div class="list-item--descr_h">Опис</div>
                    </div>
                    <div class="list-item--id">ID</div>
                </div>

                <div class="object-list-add-loading" v-show="loading">
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                </div>

                <div class="object-item" v-for="(customer) in customers"
                     :key="customer.id"
                     v-bind:class="{ linecolornice: customer.nice, linecolorex: customer.ex }"
                     v-show="list">

                    <div class="list-item--check" v-on:click.self.prevent>
                        <input type="checkbox" :value="customer.id" v-model="dataId.ids"  @change="showBtn">
                    </div>
                    <div class="item-info" v-on:click="modalInfo(customer.id)" data-bs-toggle="modal" data-bs-target="#Modal">
                        <div class="list-item--upd">{{ this.formatData(customer.updated_at) }}</div>
                        <div class="list-item--stage">{{ customer.stage_favourite ? 'У' : '' }} {{ customer.stage_adequate ? 'А' : '' }} {{ customer.stage_dreamer ? 'М' : '' }}</div>
                        <div class="list-item--phone">{{ customer.phone }}</div>
                        <div class="list-item--name">{{ customer.name }}</div>
                        <div class="list-item--room-cl">{{ customer.rooms_str }}</div>
                        <div class="list-item--price">{{ customer.price }}</div>
                        <div class="list-item--districts"><span v-for="(district) in customer.districts" :key="district.id">{{ district.name }}&nbsp;</span></div>
                        <div class="list-item--creat">{{ this.formatData(customer.created_at) }}</div>
                        <div class="list-item--description">{{ customer.description }}</div>
                    </div>
                    <div class="list-item--id">{{ customer.id }}</div>
                </div>


            </div>
            <div class="object-list-add-loading" v-show="loadingScroll">
                <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
            </div>


        </div>
    </div>
    <div class="list-bot-btn" v-bind:class="{active: isActive }">
        <button type="button" class="btn btn-primary" v-on:click="moveTo(1)">Не актуально</button>
        <button type="button" class="btn btn-primary" v-on:click="moveTo(3)" v-if="this.$store.state.uSuccess">Видалити</button>
    </div>
    <AdmCliModal ref="refModal" @sendRefresh="getCustomers" />
</template>

<script>

import AdmCliModal from './AdmCliModal.vue'
import moment from "moment";

export default {
    name: "AdmCliList",


    components: {
        AdmCliModal,
    },
    data() {
        return {
            loading: false,
            list: true,
            loadingScroll: false,
            empty: false,
            customers:[],
            lineColor: 1,
            arrow : '🠅',
            count: 0,
            dataId: {
                action: null,
                ids: [],
            },
            isActive: false,
        }
    },
    methods: {
        getCustomers() {
            this.loading = true;
            this.list = false;

            setTimeout(async () => {
                this.$store.state.filterDataClient.page = 1;
                try {
                    const response = await this.axios.post('/api/customers', this.$store.state.filterDataClient);
                    this.customers = response.data.data
                    this.getCustomersCount();
                    document.addEventListener('scroll', this.handleScroll);

                    if (response.data.data.length === 0) {
                        this.empty = true;
                    } else {
                        this.empty = false;
                    }

                } catch (error) {
                    console.log(error);
                }

                this.loading = false;
                this.list = true;
            }, 1000)
        },

        getCustomersPlus() {
            this.loadingScroll = true;
            this.axios
                .post('/api/customers', this.$store.state.filterDataClient)
                .then((response) => {
                    if (response.data.data.length > 0) {
                        response.data.data.forEach(element => this.customers.push(element));
                        this.loadingScroll = false;
                    } else {
                        this.loadingScroll = false;
                        this.empty = true;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        },
        handleScroll () {
           if (this.empty === false) {
              if (window.scrollY + 1 >= document.body.scrollHeight - window.innerHeight) {
                  this.$store.state.filterDataClient.page++;
                  this.getCustomersPlus();
              }
           }
        },
        getCustomersCount() {
            this.axios
                .post('/api/customer/count', this.$store.state.filterDataClient)
                .then((response) => {
                    console.log('count',response.data)
                    this.count = response.data
                })
                .catch((error) => {
                    console.log(error);
                })
        },
        showBtn() {
            if (this.dataId.ids.length > 0) {
                this.isActive = true;
            } else {
                this.isActive = false;
            }

        },
        moveTo(action) {
            this.dataId.action = action;
            this.axios
                .post('/api/customer/moveto', this.dataId)
                .then(() => {
                    alert("Клієнти збережено");
                    this.getCustomers();
                    this.dataId.ids = [];
                    this.showBtn();
                })
                .catch((error) => {
                    console.log(error);
                })
        },
        modalInfo(id) {
            this.$refs.refModal.getCustomerInfo(id);
        },
        setDefault() {
            this.$refs.refModal.setDataDefault();
        },
        formatData(date) {
            moment.locale('uk');
            return moment.utc(date).fromNow();
        },
        setSort() {
            //this.getBuildings();
        },
        setSortDirection(event) {
            if (this.$store.state.filterDataClient.sort === event.target.value) {
                this.$store.state.filterDataClient.sort = event.target.value
                if (this.$store.state.filterDataClient.direction === 'ASC') {
                    this.$store.state.filterDataClient.direction = 'DESC'
                    this.arrow = '🠇'
                } else {
                    this.$store.state.filterDataClient.direction = 'ASC'
                    this.arrow = '🠅'
                }
                this.getCustomers();
            } else {
                this.$store.state.filterDataClient.sort = event.target.value
                this.$store.state.filterDataClient.direction = 'ASC'
                this.arrow = '🠅';
                this.getCustomers();
            }

        },

    },
    created () {

    },

    mounted() {
        //this.getCustomers();
    }
}
</script>

<style scoped>

</style>
