<template>
    <div class="container">
        <div class="row">
            <div class="offset-md-3 col-md-6">
                <div class="login-form mx-auto">
                    <h1>Авторизація</h1>
                    <form>
                    <input type="email" class="form-control email-input" placeholder="E-mail"
                           v-model="email"
                           v-on:keypress.enter="login">
                    <i class="fa fa-user"></i>
                    <input type="password" class="form-control passw-input" placeholder="Пароль"
                           v-model="password"
                           autocomplete="false"
                           v-on:keypress.enter="login">
                    <input type="submit" value="Вхід" class="btn btn-primary login-btn"
                        @click.prevent="login">
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "AdmLogin",

    data() {
        return {
            email: "",
            password: "",
        }
    },
    methods: {
        async login() {
            try {
                await axios.get('/sanctum/csrf-cookie');
                const r = await axios.post('/login', { email: this.email, password: this.password });
                this.$router.push({ name: 'buildings', replace: true });
                localStorage.setItem('x_xsrf_token', r.config.headers['X-XSRF-TOKEN']);
            } catch (err) {
                console.log(err);
                this.email = '';
                this.password = '';
            }
        },
    },
    mounted() {
        //this.$router.push({ name: 'buildings' });
    }
}
</script>

<style scoped>

</style>
