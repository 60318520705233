<template>
    <div class="container-fluid">
        <div class="footer">
            baza.org.ua
        </div>
    </div>
</template>

<script>

export default {
    name: "AdmFooter",
    myOffCanvas: null,
    data() {
        return {

        }
    },
    methods: {



    },

    mounted() {

    }
}
</script>

<style scoped>


</style>
