<template>
    <div class="container-fluid">
        <div id="content">

<div class="filter-list">
        <div class="filter-list-tabs">
            <input class="inp-radiobox-btn" type="radio" id="my_b"
                   v-model="this.$store.state.filterData.filter_results"
                   :value=5
                   @change="getBuildings">
            <label class="form-radiobox-label" for="my_b">Мої</label>

            <input class="inp-radiobox-btn" type="radio"  id="all_b"
                   v-model="this.$store.state.filterData.filter_results"
                   :value=1
                   @change="getBuildings">
            <label class="form-radiobox-label" for="all_b">Вся база</label>

            <input class="inp-radiobox-btn" type="radio" id="agency_b"
                   v-model="this.$store.state.filterData.filter_results"
                   :value=2
                   @change="getBuildings">
            <label class="form-radiobox-label" for="agency_b">Агенство</label>

            <input class="inp-radiobox-btn" type="radio" id="partner_b"
                   v-model="this.$store.state.filterData.filter_results"
                   :value=3
                   @change="getBuildings">
            <label class="form-radiobox-label" for="partner_b">Партнерка</label>

            <input class="inp-radiobox-btn" type="radio" id="builder_b"
                   v-model="this.$store.state.filterData.filter_results"
                   :value=4
                   @change="getBuildings">
            <label class="form-radiobox-label" for="builder_b">Забудовник</label>

        </div>
        <div class="item-count">( {{count}} )</div>
        <div class="filter-list-search">
                <div class="filter_search_box">
                    <input type="text" class="form-control" id="filter_search"
                           v-model="$store.state.filterData.filters.search"
                           v-on:keypress.enter="getBuildings"
                           placeholder="Пошук">
                    <label class="lbl-search-btn" for="filter_search"
                           @click="getBuildings">Пошук</label>
                </div>
        </div>
</div>


            <div class="object-list-loading" v-show="loading">
            </div>

            <div class="object-list">
                <div class="object-list-head">



                    <div class="list-item--check"></div>
                    <div class="list-item--image">Фот</div>
                    <div class="item-info">
                        <div class="list-item--room">Кім</div>
                        <div class="list-item--price">
                            <div class="sort">
                                <input class="inp-radiobox-btn" type="radio" id="sort_p"
                                       v-model="this.$store.state.filterData.sort2"
                                       value="price"
                                       @change="setSort"
                                       @click="setSortDirection"
                                >
                                <label class="form-radiobox-label" for="sort_p">Ціна<span>{{arrow}}</span></label>
                            </div>
                        </div>
                        <div class="list-item--district">Район</div>
                        <div class="list-item--floor">
                            <div class="sort">
                                <input class="inp-radiobox-btn" type="radio" id="sort_f"
                                       v-model="this.$store.state.filterData.sort2"
                                       value="floor"
                                       @change="setSort"
                                       @click="setSortDirection"
                                >
                                <label class="form-radiobox-label" for="sort_f">П<span>{{arrow}}</span></label>
                            </div>
                        </div>
                        <div class="list-item--max_floor">Пов</div>
                        <div class="list-item--type">Планування</div>
                        <div class="list-item--squar">
                            <div class="sort">
                                <input class="inp-radiobox-btn" type="radio" id="sort_s"
                                       v-model="this.$store.state.filterData.sort2"
                                       value="squaring_total"
                                       @change="setSort"
                                       @click="setSortDirection"
                                >
                                <label class="form-radiobox-label" for="sort_s">Площа<span>{{arrow}}</span></label>
                            </div>
                        </div>
                        <div class="list-item--descr_h">Опис</div>
                        <div class="list-item--realt">Ріелтор</div>
                        <div class="list-item--phone">Телефон</div>
                        <div class="list-item--upd">
                            <div class="sort">
                                <input class="inp-radiobox-btn" type="radio" id="sort_u"
                                       v-model="this.$store.state.filterData.sort2"
                                       value="updated_at"
                                       @change="setSort"
                                       @click="setSortDirection"
                                >
                                <label class="form-radiobox-label" for="sort_u">Оновл<span>{{arrow}}</span></label>
                            </div>
                        </div>
                        <div class="list-item--creat">
                            <div class="sort">
                                <input class="inp-radiobox-btn" type="radio" id="sort_c"
                                       v-model="this.$store.state.filterData.sort2"
                                       value="created_at"
                                       @change="setSort"
                                       @click="setSortDirection"
                                >
                                <label class="form-radiobox-label" for="sort_c">Створ<span>{{arrow}}</span></label>
                            </div>
                        </div>
                    </div>
                    <div class="list-item--id">ID</div>


                </div>

                <div class="object-list-add-loading" v-show="loading">
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                </div>

                <div class="object-item" v-for="(building) in buildings"
                     :key="building.id"
                     v-bind:class="{ linecolornice: building.nice, linecolorex: building.ex }"
                     v-show="list">

                    <div class="list-item--check" v-on:click.self.prevent>
                        <input type="checkbox" :value="building.id" v-model="dataId.ids"  @change="showBtn">
                    </div>
                    <div class="list-item--image" v-on:click.self.prevent>
                        <div  v-if="building.image" class="item--image">
                            <img v-bind:src="'https://baza.org.ua/'+building.image.path_preview" @click="startFancy(building.images)"/>
                        </div>
                    </div>
                    <div class="item-info" v-on:click="modalInfo(building.id)" data-bs-toggle="modal" data-bs-target="#Modal">
                        <div class="list-item--room">{{ building.rooms }}</div>
                        <div class="list-item--price">{{ building.price }}</div>
                        <div class="list-item--district">{{ building.district ? building.district.name : '' }}</div>
                        <div class="list-item--floor">{{ building.floor }}</div>
                        <div class="list-item--max_floor">{{ building.max_floor }}</div>
                        <div class="list-item--type">{{ building.type ? building.type.name : '' }}</div>
                        <div class="list-item--squar">{{ building.squaring_total }} м2</div>

                        <div class="list-item--descr">{{ building.description }}</div>

                        <div class="list-item--realt" v-if="building.realtor_id === 98" style="color:#1c70e0;">{{ building.custom_realtor }}</div>
                        <div class="list-item--realt" v-else >{{ building.custom_realtor }}</div>

                        <div class="list-item--phone" v-if="building.realtor_id === 98" style="color:#1c70e0;">{{ building.custom_realtor_phone }}</div>
                        <div class="list-item--phone" v-else >{{ building.custom_realtor_phone }}</div>

                        <div class="list-item--upd">{{ this.formatData(building.updated_at) }}</div>
                        <div class="list-item--creat">{{ this.formatData(building.created_at) }}</div>
                    </div>
                    <div class="list-item--id">{{ building.id }}</div>
                </div>


            </div>
            <div class="object-list-add-loading" v-show="loadingScroll">
                <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
            </div>

        </div>
    </div>
    <div class="list-bot-btn" v-bind:class="{active: isActive }">
        <button type="button" class="btn btn-primary" v-on:click="moveTo(1)">Продано</button>
        <button type="button" class="btn btn-primary" v-on:click="moveTo(2)">Знято з продажу</button>
        <button type="button" class="btn btn-primary" v-on:click="moveTo(3)" v-if="this.$store.state.uSuccess">Видалити</button>
    </div>
    <AdmObjModal ref="refModal" @sendRefresh="getBuildings" />

</template>

<script>

import AdmObjModal from './AdmObjModal.vue'
import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox";

let moment = require('moment'); // require


export default {
    name: "AdmObjList",
    props: ['id'],

    components: {
        AdmObjModal,
    },
    data() {
        return {
            loading: false,
            list: true,
            loadingScroll: false,
            empty: false,
            buildings:[],
            lineColor: 1,
            gallerys: [],
            arrow : '🠅',
            count: 0,
            dataId: {
                action: null,
                ids: [],
            },
            isActive: false,
        }
    },
    methods: {
        startFancy(images) {

            let gallery = new Array();
            images.forEach(function(element, index) {
                gallery[index] = {src: 'https://baza.org.ua/'+element.path};
            });

            Fancybox.show(gallery, {}); //starts fancybox with the gallery object
        },


        getBuildings() {
            this.loading = true;
            this.list = false;

            setTimeout(async () => {
                this.$store.state.filterData.page = 1;
                try {
                    const response = await this.axios.post('/api/buildings', this.$store.state.filterData);
                    this.buildings = response.data.data;
                    this.getBuildingsCount();
                    document.addEventListener('scroll', this.handleScroll);

                    if (response.data.data.length === 0) {
                        this.empty = true;
                    } else {
                        this.empty = false;
                    }

                } catch (error) {
                    console.log(error);
                }
                this.loading = false;
                this.list = true;
            }, 1000)
        },

        async getBuildingsPlus() {

            this.loadingScroll = true;
            try {
                const response = await this.axios.post('/api/buildings', this.$store.state.filterData);
                if (response.data.data.length > 0) {
                    await response.data.data.forEach(element => this.buildings.push(element));
                    this.loadingScroll = false;
                } else {
                    this.loadingScroll = false;
                    this.empty = true;
                }


            } catch (error) {
                console.log(error);
            }

        },
       handleScroll () {
         if (this.empty === false) {
            if(window.scrollY+1 >= document.body.scrollHeight - window.innerHeight) {
                this.loadingScroll = true;
                setTimeout(() => {
                    this.$store.state.filterData.page++;
                    this.getBuildingsPlus();
                }, 1000)
            }
         }
        },

        /*
        getBuildingsPlus2() {
            this.loading = true;
            setTimeout(() => {
            this.axios
                .post('/api/buildings', this.$store.state.filterData)
                .then((response) => {
                    if (response.data.data.length > 0) {
                        response.data.data.forEach(element => this.buildings.push(element));
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                this.loading = false;
            }, 1000)
        },


                getBuildings2() {
                    this.loading = true;
                    setTimeout(() => {
                        this.axios
                        .post('/api/buildings', this.$store.state.filterData)
                        .then((response) => {
                            this.buildings = response.data.data;
                            this.getBuildingsCount();
                            document.addEventListener('scroll', this.handleScroll);
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                        this.loading = false;
                    }, 1000)
                },

         */


        getBuildingsCount() {
            this.axios
                .post('/api/buildings/count', this.$store.state.filterData)
                .then((response) => {
                    this.count = response.data;
                })
                .catch((error) => {
                    console.log(error);
                })
        },
        showBtn() {
            if (this.dataId.ids.length > 0) {
                this.isActive = true;
            } else {
                this.isActive = false;
            }

        },
        moveTo(action) {
            this.dataId.action = action;
            this.axios
                .post('/api/buildings/moveto', this.dataId)
                .then(() => {
                    alert("Об'єкти збережено");
                    this.getBuildings();
                    this.dataId.ids = [];
                    this.showBtn();
                })
                .catch((error) => {
                    console.log(error);

                })
        },
        modalInfo(id) {
            this.$refs.refModal.getBuildInfo(id);
        },
        setDefault() {
            this.$refs.refModal.setDataDefault();
        },
        formatData(date) {
            moment.locale('uk');
            return moment.utc(date).fromNow();
        },
        setSort() {
            //this.getBuildings();
        },
        setSortDirection(event) {
            if (this.$store.state.filterData.sort === event.target.value) {
                this.$store.state.filterData.sort = event.target.value
                if (this.$store.state.filterData.direction === 'ASC') {
                    this.$store.state.filterData.direction = 'DESC'
                    this.arrow = '🠇'
                } else {
                    this.$store.state.filterData.direction = 'ASC'
                    this.arrow = '🠅'
                }
                this.getBuildings();
            } else {
                this.$store.state.filterData.sort = event.target.value
                this.$store.state.filterData.direction = 'ASC'
                this.arrow = '🠅';
                this.getBuildings();
            }
        },



    },
    created () {

    },

    mounted() {
        //this.getBuildings();
    }
}

























</script>

<style scoped>

</style>




