<template>


    <div class="modal fade" id="Modal" tabindex="-1" aria-labelledby="ModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="modal-header-info" v-if="data.id">
                        <span>{{ data.author ? 'Автор: '+data.author.name : '' }}</span>
                        <span class="modal-header-data">{{ data.created_at ? this.formatData(data.created_at) : '' }}</span>
                        <span>{{ data.id ? 'ID: '+data.id : '' }}</span>
                    </div>
                    <button type="button" id="close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    v-on:click="setDataDefault()"></button>
                </div>
                <div class="modal-body">

                    <div class="row">
                        <div class="col-md-12 col-lg-5 col-xl-5">
                            <div class="obj-form-row">

                                <div class="orang">
                                    <input type="checkbox" class="inp-checkbox-btn" id="rooms_1"
                                           :value="1"
                                           v-model="data.rooms"
                                           checked="data.rooms[1]">
                                    <label class="lbl-checkbox-btn" for="rooms_1">1 кім</label>
                                    <input type="checkbox" class="inp-checkbox-btn" id="rooms_2"
                                           :value="2"
                                           v-model="data.rooms"
                                           checked="data.rooms[2]">
                                    <label class="lbl-checkbox-btn" for="rooms_2">2 кім</label>
                                    <input type="checkbox" class="inp-checkbox-btn" id="rooms_3"
                                           :value="3"
                                           v-model="data.rooms"
                                           checked="data.rooms[3]">
                                    <label class="lbl-checkbox-btn" for="rooms_3">3 кім</label>
                                    <input type="checkbox" class="inp-checkbox-btn" id="rooms_4"
                                           :value="4"
                                           v-model="data.rooms"
                                           checked="data.rooms[4]">
                                    <label class="lbl-checkbox-btn" for="rooms_4">4 кім</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-7 col-xl-7">
                            <div class="obj-form-row">

                                <div class="green">

                                    <input type="checkbox" class="inp-checkbox-btn" id="type_zr"
                                           :value="1" v-model="data.types_id">
                                    <label class="lbl-checkbox-btn" for="type_zr">НБ з ремонтом</label>

                                    <input type="checkbox" class="inp-checkbox-btn" id="type_zd"
                                           :value="2" v-model="data.types_id">
                                    <label class="lbl-checkbox-btn" for="type_zd">НБ здана</label>

                                    <input type="checkbox" class="inp-checkbox-btn" id="type_bd"
                                           :value="3" v-model="data.types_id">
                                    <label class="lbl-checkbox-btn" for="type_bd">НБ будівництво</label>

                                    <input type="checkbox" class="inp-checkbox-btn" id="type_vtor"
                                           :value="1" v-model="data.type_vtor" :checked="data.type_vtor">
                                    <label class="lbl-checkbox-btn" for="type_vtor">Вторинний</label>

                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="filter_districts">
                            <div v-for="district in this.$store.state.dataDistricts" :key="district.id" class="blue">
                                <input type="checkbox"
                                       class="inp-checkbox-btn"
                                       v-bind:id="'district-'+ district.id"
                                       :value="district.id"
                                       v-model="data.districts_id"
                                >
                                <label class="lbl-checkbox-btn"
                                       v-bind:for="'district-'+ district.id">{{ district.name }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="=col-md-12 col-lg-5 col-xl-5">
                            <div class="obj-form-row">

                                <div class="magenta">
                                    <input type="checkbox" class="inp-checkbox-btn" id="type_c"
                                           :value="15" v-model="data.types_id">
                                    <label class="lbl-checkbox-btn" for="type_c">Комерція</label>

                                    <input type="checkbox" class="inp-checkbox-btn" id="type_b"
                                           :value="16" v-model="data.types_id">
                                    <label class="lbl-checkbox-btn" for="type_b">Будинок</label>

                                    <input type="checkbox" class="inp-checkbox-btn" id="type_z"
                                           :value="17" v-model="data.types_id">
                                    <label class="lbl-checkbox-btn" for="type_z">Земля</label>

                                    <input type="checkbox" class="inp-checkbox-btn" id="type_o"
                                           :value="18" v-model="data.types_id">
                                    <label class="lbl-checkbox-btn" for="type_o">Оренда</label>

                                    <input type="checkbox" class="inp-checkbox-btn" id="type_g"
                                           :value="9" v-model="data.types_id">
                                    <label class="lbl-checkbox-btn" for="type_g">Гуртожиток</label>

                                </div>
                            </div>
                        </div>


                    </div>

                    <div class="row">
                        <div class="col-md-12 col-lg-5 col-xl-5">
                            <div class="obj-form-row">

                                <input type="text" class="form-control cust-form--price"
                                       v-model.number="data.price"
                                       placeholder="Ціна"
                                       v-bind:class="{ hasError: hasErrorPrice }">

                                <input type="text" class="form-control cust-form--phone"
                                       v-model="data.phone"
                                       placeholder="Телефон"
                                       v-bind:class="{ hasError: hasErrorPhone }">

                                <input type="text" class="form-control cust-form--name"
                                       v-model="data.name"
                                       placeholder="Ім'я"
                                       v-bind:class="{ hasError: hasErrorName }">
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-7 col-xl-7">
                            <div class="obj-form-row">

                                <div class="magenta">
                                    <input type="checkbox" class="inp-checkbox-btn" id="stage_favourite"
                                           :value="1" v-model="data.stage_favourite" :checked="data.stage_favourite">
                                    <label class="lbl-checkbox-btn" for="stage_favourite">У</label>

                                    <input type="checkbox" class="inp-checkbox-btn" id="stage_adequate"
                                           :value="1" v-model="data.stage_adequate" :checked="data.stage_adequate">
                                    <label class="lbl-checkbox-btn" for="stage_adequate">А</label>

                                    <input type="checkbox" class="inp-checkbox-btn" id="stage_dreamer"
                                           :value="1" v-model="data.stage_dreamer" :checked="data.stage_dreamer">
                                    <label class="lbl-checkbox-btn" for="stage_dreamer">М</label>
                                </div>


                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-5">
                            <div class="form-floating">
                                    <textarea class="form-control"
                                              id="description"
                                              placeholder="Опис"
                                              v-model="data.description"
                                              style="height: 100px"
                                              v-bind:class="{ hasError: hasErrorDescription }"
                                    ></textarea>
                                <label for="description">Опис</label>
                            </div>
                        </div>
                        <div class="col-md-4">

                            <div class="form-floating">
                                    <textarea class="form-control"
                                              id="note"
                                              placeholder="Примітка"
                                              v-model="data.note"
                                              style="height: 100px">

                                    </textarea>
                                <label for="note">Примітка</label>
                            </div>


                        </div>
                        <div class="col-md-3">



                        </div>
                    </div>


                    <div class="row">
                        <div class="=col-md-12 col-lg-5 col-xl-5">
                            <div class="obj-form-row">

                                <div class="orang">
                                    <input type="checkbox" class="inp-checkbox-btn" id="floor_first"
                                           :value="1" v-model="data.floor_first" :checked="data.floor_first">
                                    <label class="lbl-checkbox-btn" for="floor_first">Перший</label>

                                    <input type="checkbox" class="inp-checkbox-btn" id="floor_middle"
                                           :value="1" v-model="data.floor_middle" :checked="data.floor_middle">
                                    <label class="lbl-checkbox-btn" for="floor_middle">Середній</label>

                                    <input type="checkbox" class="inp-checkbox-btn" id="floor_last"
                                           :value="1" v-model="data.floor_last" :checked="data.floor_last">
                                    <label class="lbl-checkbox-btn" for="floor_last">Останній</label>
                                </div>
                            </div>
                        </div>
                        <div class="=col-md-12 col-lg-7 col-xl-7">
                            <div class="obj-form-row">

                                <div class="blue">
                                    <input type="checkbox" class="inp-checkbox-btn" id="material_panel"
                                           :value="1" v-model="data.material_panel" :checked="data.material_panel">
                                    <label class="lbl-checkbox-btn" for="material_panel">Панель</label>
                                    <input type="checkbox" class="inp-checkbox-btn" id="material_brick"
                                           :value="1" v-model="data.material_brick" :checked="data.material_brick">
                                    <label class="lbl-checkbox-btn" for="material_brick">Цегла</label>
                                </div>

                                <div class="green">
                                    <input type="checkbox" class="inp-checkbox-btn" id="repair_with"
                                           :value="1" v-model="data.repair_with" :checked="data.repair_with">
                                    <label class="lbl-checkbox-btn" for="repair_with">З ремонтом</label>
                                    <input type="checkbox" class="inp-checkbox-btn" id="repair_ls"
                                           :value="1" v-model="data.repair_ls" :checked="data.repair_ls">
                                    <label class="lbl-checkbox-btn" for="repair_ls">Ж.С.</label>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>

                <div class="modal-footer">

                    <div class="row" v-if="!data.trash">
                        <div class="col-6 col-md-4 col-lg-3 col-xl-3 d-flex align-items-center p-0 order-1 order-lg-1">
                            <div v-if="!data.id"></div>
                            <div v-if="data.id" class="del-box">
                                <button :disabled="!delCheck" type="button" class="btn btn-danger" v-on:click="delCustomer(data.id)">Видалити</button>
                                <div class="form-check form-switch" v-if="this.$store.state.uSuccess">
                                    <input class="form-check-input del-valid" type="checkbox" v-model="delCheck">
                                </div>
                            </div>
                        </div>

                        <div class="col-3 col-md-4 col-lg-2 col-xl-2 d-flex align-items-center p-0 order-1 order-lg-1">
                            <select class="form-select" name="status" v-model="data.status">
                                <option selected :value=0>В роботі</option>
                                <option :value=1>Не актуально</option>
                            </select>
                        </div>
                        <div class="col-3 col-md-4 col-lg-2 col-xl-2 d-flex justify-content-end p-0 order-1 order-lg-1">
                            <button type="button" class="btn btn-primary" v-on:click="data.id ? updateCustomer() : createCustomer()">Зберегти</button>
                        </div>
                    </div>

                    <div class="row" v-else>
                        <div class="col-12 col-md-12 col-lg-12 col-xl-12 align-items-center p-0 order-0 order-lg-1 marg-dl">
                            <button type="button" class="btn btn-success mx-1" v-on:click="restoreCustomer(data.id)">Відновити</button>
                            <button type="button" class="btn btn-danger mx-1" v-on:click="delTreshCustomer(data.id)">Видалити з бази</button>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>

</template>

<script>


import moment from "moment";
//var moment = require('moment'); // require

export default {
    name: "AdmCliModal",
    components: {

    },
    data() {
        return {
            enabled: false,
            id: null,
            delCheck: false,
            districts:[],

            hasErrorPrice: false,
            hasErrorPhone: false,
            hasErrorName: false,
            hasErrorDescription: false,


            data: {
                id: null,
                name: null,
                phone: null,
                price: null,
                description: '',
                note: '',
                author_id:  this.$store.state.loginId,
                status: 0,

                districts: [],
                districts_id: [],

                types: [],
                types_id: [],

                rooms: [],
                type: '',
                repair: '',

                floor_first: false,
                floor_middle: false,
                floor_last: false,

                stage_favourite: false,
                stage_adequate: false,
                stage_dreamer: false,

                material_panel: false,
                material_brick: false,

                repair_with: false,
                repair_ls: false,

                type_vtor: false,
            }
        }
    },
    methods: {
        async createCustomer() {
            //start animation
            try {
                const response = await this.axios.post('/api/customer/create', this.data);
                this.resetError();
                alert('Добавлено');
                this.getCustomerInfo(response.data.id);
                this.refreshList();
            } catch (err) {
                const {data, status} = err.response;
                if (status === 422) {
                    this.getError(data.errors);
                }
            }
            //stop animation

        },
        updateCustomer() {
            this.axios
                .post('/api/customer/edit', this.data)
                .then(() => {
                    this.resetError();
                    alert('Збережено');
                    this.getCustomerInfo(this.data.id);
                    this.refreshList();
                })
                .catch((error) => {
                    this.getError(error.response.data.errors)
                })
        },
        checkDistFn() {
            return true;
        },

        getDistricts() {
            this.axios
                .post('/api/districts')
                .then((response) => {
                    this.districts = response.data.data;
                })
                .catch((error) => {
                    console.log(error.response.data);
                })
        },
        getRepairs() {
            this.axios
                .post('/api/repairs')
                .then((response) => {
                    this.repairs = response.data.data;
                })
                .catch((error) => {
                    console.log(error.response.data);
                })
        },
        getTypes() {
            alert();
            this.axios
                .post('/api/types')
                .then((response) => {
                    this.types = response.data.data;
                })
                .catch((error) => {
                    console.log(error.response.data);
                })
        },



        getCustomerInfo(id) {
            this.resetError();

            this.axios
                .get('/api/customer/'+id)
                .then((response) => {
                    this.dataCustomer = response.data.data;
                    this.data = this.dataCustomer;
                    this.images = response.data.images;
                    this.delCheck= false;
                    this.$store.state.customerId = id;
                })
                .catch((error) => {
                    console.log(error);
                })
        },
        delCustomer(id) {
            this.axios
                .get('/api/customer/trash/'+id)
                .then(() => {
                    alert("Клієнт видалений");
                    document.getElementById('close').click();
                    this.refreshList();
                })
                .catch((error) => {
                    console.log(error);
                })
        },

        refreshList() {
            this.$emit('sendRefresh');
        },

            formatData(date) {
                moment.locale('uk');
                return moment(date, "YYYYMMDD").format('DD MM YYYY, h:mm');
            },

        setDataDefault() {
            this.data.id = null;
            this.data.name = null;
            this.data.phone = null;
            this.data.price = null;
            this.data.description = '';
            this.data.note = '';
            this.data.author_id = null;
            this.data.status = 0;

            this.data.districts = [];
            this.data.districts_id = [];

            this.data.types = [];
            this.data.types_id = [];

            this.data.rooms = [];
            this.data.type = '';
            this.data.repair = '';

            this.data.floor_first = false;
            this.data.floor_middle = false;
            this.data.floor_last = false;

            this.data.stage_favourite = false;
            this.data.stage_adequate = false;
            this.data.stage_dreamer = false;

            this.data.material_panel = false;
            this.data.material_brick = false;

            this.data.repair_with = false;
            this.data.repair_ls = false;

            this.data.type_vtor = false;

            this.data.author_id = this.$store.state.loginId;

            this.resetError();


        },
        getError(error) {

            this.resetError();

            if (error.price) {
                this.hasErrorPrice = true;
            }
            if (error.phone) {
                this.hasErrorPhone = true;
            }
            if (error.description) {
                this.hasErrorDescription = true;
            }
        },
        resetError(){
            this.hasErrorPrice = false;
            this.hasErrorPhone = false;
            this.hasErrorDescription = false;

        },
        restoreCustomer(id) {
            this.axios
                .get('/api/customer/restore/'+id)
                .then(() => {
                    alert("Клієнта відновлено");
                    document.getElementById('close').click();
                    this.refreshList();
                })
                .catch((error) => {
                    console.log(error);
                })
        },
        delTreshCustomer(id) {
            this.axios
                .get('/api/customer/delltrash/'+id)
                .then(() => {
                    alert("Клієнта видалено з бази");
                    document.getElementById('close').click();
                    this.refreshList();
                })
                .catch((error) => {
                    console.log(error);
                })
        },

    },
    mounted() {
        //this.getDistricts();
        //this.getRepairs();
        //this.getTypes();
    },
    beforeMount(){

    }

}


</script>

<style scoped>

</style>




