<template>
    <div class="container-fluid">
        <div id="filters">

            <div class="filter_districts">
                <div v-for="district in this.$store.state.dataDistricts" :key="district.id" class="blue">
                    <input type="checkbox"
                           class="inp-checkbox-btn"
                           v-bind:id="'district-'+ district.id"
                           :value="district.id"
                           v-model="data.filters.districts"
                           @change="sendFilters"
                    >
                    <label class="lbl-checkbox-btn"
                           v-bind:for="'district-'+ district.id">{{ district.name }}
                    </label>
                </div>
            </div>
<div class="filters-grup-2">
            <div class="filter_type">
                <div class="green">
                    <input type="checkbox" class="inp-checkbox-btn" id="type-1"
                           :value="1" v-model="data.filters.type" @change="sendFilters">
                    <label class="lbl-checkbox-btn" for="type-1">НБ з ремонтом</label>
                </div>
                <div class="green">
                    <input type="checkbox" class="inp-checkbox-btn" id="type-2"
                           :value="2" v-model="data.filters.type" @change="sendFilters">
                    <label class="lbl-checkbox-btn" for="type-2">НБ здана</label>
                </div>
                <div class="green">
                    <input type="checkbox" class="inp-checkbox-btn" id="type-3"
                           :value="3" v-model="data.filters.type" @change="sendFilters">
                    <label class="lbl-checkbox-btn" for="type-3">НБ будівництво</label>
                </div>
                <div class="green">
                    <input type="checkbox" class="inp-checkbox-btn" id="type-99"
                           :value="99" v-model="data.filters.type" @change="sendFilters">
                    <label class="lbl-checkbox-btn" for="type-99">Вторинний</label>
                </div>

            </div>
            <div class="filter_type">
                <div class="magenta">
                    <input type="checkbox" class="inp-checkbox-btn" id="type-14"
                           :value="14" v-model="data.filters.type" @change="sendFilters">
                    <label class="lbl-checkbox-btn" for="type-14">Гараж</label>
                </div>
                <div class="magenta">
                    <input type="checkbox" class="inp-checkbox-btn" id="type-15"
                           :value="15" v-model="data.filters.type" @change="sendFilters">
                    <label class="lbl-checkbox-btn" for="type-15">Комерція</label>
                </div>
                <div class="magenta">
                    <input type="checkbox" class="inp-checkbox-btn" id="type-16"
                           :value="16" v-model="data.filters.type" @change="sendFilters">
                    <label class="lbl-checkbox-btn" for="type-16">Будинок</label>
                </div>
                <div class="magenta">
                    <input type="checkbox" class="inp-checkbox-btn" id="type-17"
                           :value="17" v-model="data.filters.type" @change="sendFilters">
                    <label class="lbl-checkbox-btn" for="type-17">Земля</label>
                </div>
                <div class="magenta">
                    <input type="checkbox" class="inp-checkbox-btn" id="type-18"
                           :value="18" v-model="data.filters.type" @change="sendFilters">
                    <label class="lbl-checkbox-btn" for="type-18">Оренда</label>
                </div>
                <div class="magenta">
                    <input type="checkbox" class="inp-checkbox-btn" id="type-19"
                           :value="19" v-model="data.filters.type" @change="sendFilters">
                    <label class="lbl-checkbox-btn" for="type-19">Кімната</label>
                </div>
            </div>

            <div class="filter_rooms">
                <div class="orang">
                    <input type="checkbox" class="inp-checkbox-btn" id="rooms-1"
                           :value="1" v-model="data.filters.rooms" @change="sendFilters">
                    <label class="lbl-checkbox-btn" for="rooms-1">1 кім</label>
                </div>
                <div class="orang">
                    <input type="checkbox" class="inp-checkbox-btn" id="rooms-2"
                           :value="2" v-model="data.filters.rooms" @change="sendFilters">
                    <label class="lbl-checkbox-btn" for="rooms-2">2 кім</label>
                </div>
                <div class="orang">
                    <input type="checkbox" class="inp-checkbox-btn" id="rooms-3"
                           :value="3" v-model="data.filters.rooms" @change="sendFilters">
                    <label class="lbl-checkbox-btn" for="rooms-3">3 кім</label>
                </div>
                <div class="orang">
                    <input type="checkbox" class="inp-checkbox-btn" id="rooms-4"
                           :value="4" v-model="data.filters.rooms" @change="sendFilters">
                    <label class="lbl-checkbox-btn" for="rooms-4">4 кім</label>
                </div>
                <div class="orang">
                    <input type="checkbox" class="inp-checkbox-btn" id="rooms-5"
                           :value="5" v-model="data.filters.rooms" @change="sendFilters">
                    <label class="lbl-checkbox-btn" for="rooms-5">5 кім</label>
                </div>
            </div>
</div>

            <div class="filter-price">
                <input type="text" v-model="data.filters.price[0]" id="label-price-1" @change="sliderChange" class="form-control">
                <div class="filter-price-slider">
                    <vue-slider ref="slider"
                                @drag-end="sliderChange"
                                v-model="data.filters.price"
                                v-bind="options"
                                :adsorb="false"
                                :marks="marks"
                                :enable-cross="false"
                    ></vue-slider>
                </div>
                <input type="text" v-model="data.filters.price[1]" id="label-price-2" @change="sliderChange" class="form-control">
                <div class="filter-price-btn">
                    <input type="checkbox" class="inp-checkbox-price" id="price-max"
                           value=true v-model="data.filters.price_max" @change="sendFilters">
                    <label class="lbl-checkbox-price" for="price-max"><span>8</span></label>
                </div>
            </div>
<div class="filters-grup-3">
    <div class="filter-floor">
        <input type="text" v-model="data.filters.floor[0]" id="label-floor-1" @change="sliderFloorChange" class="form-control">
        <div class="filter-floor-slider">
            <vue-slider ref="sliderFloor"
                        @drag-end="sliderFloorChange"
                        v-model="data.filters.floor"
                        v-bind="optionsFloor"
                        :adsorb="false"
                        :marks="marksFloor"
                        :enable-cross="false"
            ></vue-slider>
        </div>
        <input type="text" v-model="data.filters.floor[1]" id="label-floor-2" @change="sliderFloorChange" class="form-control">
    </div>

    <div class="filter-squar">
        <input type="text" v-model="data.filters.squar[0]" id="label-squar-1" @change="sliderSquarChange" class="form-control">
        <div class="filter-squar-slider">
            <vue-slider ref="sliderSquar"
                        @drag-end="sliderSquarChange"
                        v-model="data.filters.squar"
                        v-bind="optionsSquar"
                        :adsorb="false"
                        :marks="marksSquar"
                        :enable-cross="false"
            ></vue-slider>
        </div>
        <input type="text" v-model="data.filters.squar[1]" id="label-squar-2" @change="sliderSquarChange" class="form-control">
    </div>
</div>

<div class="filters-grup-4">

            <div class="filter_type orang">
                <div>
                    <input type="checkbox" class="inp-checkbox-btn" id="floor_first"
                           value=true v-model="data.filters.floor_first" @change="sendFilters">
                    <label class="lbl-checkbox-btn" for="floor_first">Перший</label>
                </div>
                <div>
                    <input type="checkbox" class="inp-checkbox-btn" id="floor_middle"
                           value=true v-model="data.filters.floor_middle" @change="sendFilters">
                    <label class="lbl-checkbox-btn" for="floor_middle">Середній</label>
                </div>
                <div>
                    <input type="checkbox" class="inp-checkbox-btn" id="floor_last"
                           value=true v-model="data.filters.floor_last" @change="sendFilters">
                    <label class="lbl-checkbox-btn" for="floor_last">Останній</label>
                </div>
            </div>


            <div class="filter_select">
                <select class="form-select" v-model="data.filters.ind_heating" @change="sendFilters">
                    <option selected :value=null>Опалення</option>
                    <option value="individual">Індивідуальне</option>
                    <option value="centralized">Централізоване</option>
                </select>
            </div>

            <div class="filter_select">
                <select class="form-select" v-model="data.filters.material" @change="sendFilters">
                    <option selected :value=null>Матеріал</option>
                    <option value="brick">Цегла</option>
                    <option value="panel">Панель</option>
                    <option value="monolith">Моноліт</option>
                </select>
            </div>



                <div class="filter_select">
                    <select class="form-select" v-model="data.filters.realtor" @change="sendFilters">
                        <option selected :value=null>Рієлтор</option>
                        <option v-for="user in this.$store.state.dataUsers" :key="user.id"
                                v-bind:value="user.id">{{ user.name }}
                        </option>
                    </select>
                </div>

                <div class="filters-check">
                    <div class="filter_nice">
                        <input type="checkbox" class="inp-checkbox-def"
                               id="filter_nice"
                               v-model="data.filters.nice"
                               @change="sendFilters">
                        <label class="lbl-checkbox-def" for="filter_nice">Цікаве</label>
                    </div>
                    <div class="filter_ex">
                        <input type="checkbox" class="inp-checkbox-def"
                               id="filter_ex"
                               v-model="data.filters.ex"
                               @change="sendFilters">
                        <label class="lbl-checkbox-def" for="filter_ex">Екс</label>
                    </div>
                </div>

            <div class="filter_select">
                <select class="form-select" v-model="data.filters.status" @change="sendFilters">
                    <option selected :value=0>Активне</option>
                    <option :value=1>Продане</option>
                    <option :value=2>Знято з продажу</option>
                </select>
            </div>

            <div class="filter_select">
                <button class="bt-filt-clear" @click="setFiltersDefault">Очистити</button>
            </div>
</div>



        </div>
    </div>

</template>

<script>

import { reactive, toRefs } from 'vue';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';

export default {
    name: "AdmObjFilters",
    components: {
        VueSlider,
    },
    setup() {
        const data = reactive({
            marks: [0,
                10000,
                20000,
                30000,
                40000,
                50000,
                60000,
                70000,
                80000,
                90000,
                100000,
                110000,
                120000,
                130000,
                140000,
                150000,
            ],
            marksFloor: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
            marksSquar: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200],
        })
        return toRefs(data)
    },
    data() {
        return {

            districtsbtn: [],
            maxPprice: 5000000,
            options: {
                dotSize: 14,
                width: 'auto',
                height: 4,
                contained: false,
                direction: 'ltr',
                data: null,
                dataLabel: 'label',
                dataValue: 'value',
                min: 0,
                max: 150000,
                interval: 100,
                disabled: false,
                clickable: true,
                duration: 0.5,
                adsorb: false,
                lazy: false,
                tooltip: 'active',
                tooltipPlacement: 'top',
                tooltipFormatter: void 0,
                useKeyboard: false,
                keydownHook: null,
                dragOnClick: false,
                enableCross: true,
                fixed: false,
                minRange: void 0,
                maxRange: void 0,
                order: true,
                marks: false,
                dotOptions: void 0,
                dotAttrs: void 0,
                process: true,
                dotStyle: void 0,
                railStyle: void 0,
                processStyle: void 0,
                tooltipStyle: void 0,
                stepStyle: void 0,
                stepActiveStyle: void 0,
                labelStyle: void 0,
                labelActiveStyle: void 0,
            },
            optionsFloor: {
                dotSize: 14,
                width: 'auto',
                height: 4,
                contained: false,
                direction: 'ltr',
                data: null,
                dataLabel: 'label',
                dataValue: 'value',
                min: 1,
                max: 20,
                interval: 1,
                disabled: false,
                clickable: true,
                duration: 0.5,
                adsorb: false,
                lazy: false,
                tooltip: 'active',
            },
            optionsSquar: {
                dotSize: 14,
                width: 'auto',
                height: 4,
                contained: false,
                direction: 'ltr',
                data: null,
                dataLabel: 'label',
                dataValue: 'value',
                min: 10,
                max: 200,
                interval: 10,
                disabled: false,
                clickable: true,
                duration: 0.5,
                adsorb: false,
                lazy: false,
                tooltip: 'active',
            },
            data: {

                filters: {
                    type: [],
                    rooms: [],
                    repair: [],
                    districts: [],
                    reverse: false,
                    price: [0, 150000],
                    price_max: true,
                    floor: [1, 20],
                    squar: [10, 200],
                    floor_first: false,
                    floor_middle: false,
                    floor_last: false,
                    realtor: null,
                    department: false,
                    sold: false,
                    closed: false,
                    search: '',
                    date_updated: [],
                    date_created: [],
                    squaring_total: [],
                    squaring_kitchen: [],
                    ind_heating: null,
                    material: null,
                    joint: false,
                    ex: false,
                    nice: false,
                    status: 0,
                    whose: 0,
                },
                filter_results: 1,
                sort: 'price',
                sort2: 'price',
                direction: 'ASC',
                page: 1
            }
        }
    },
    methods: {
        sliderChange(){
            this.data.filters.price_max = false;
            this.$refs.slider.setValue([this.data.filters.price[0], this.data.filters.price[1]]);
            this.sendFilters();
        },
        sliderFloorChange(){
            this.data.filters.floor_first = false;
            this.data.filters.floor_middle = false;
            this.data.filters.floor_last = false;
            this.$refs.sliderFloor.setValue([this.data.filters.floor[0], this.data.filters.floor[1]]);
            this.sendFilters();
        },
        sliderSquarChange(){
            this.$refs.sliderSquar.setValue([this.data.filters.squar[0], this.data.filters.squar[1]]);
            this.sendFilters();
        },
        sendFilters() {
            this.$store.state.filterData = this.data;
            //console.log(this.data);
            this.$emit('dataFilters', this.data);
        },

        getFiltersDistricts() {
            this.axios
                .post('/api/districts')
                .then((response) => {
                    this.districtsbtn = response.data.data;
                })
                .catch((error) => {
                    console.log(error.response.data);
                })
        },
        setFiltersDefault() {
            this.data.filters.type = [];
                this.data.filters.rooms = [];
                this.data.filters.repair = [];
                this.data.filters.districts = [];
                this.data.filters.reverse = false;
                this.data.filters.price = [0, 150000];
                this.data.filters.price_max = true;
                this.data.filters.floor = [1, 20];
                this.data.filters.squar = [10, 200];
            this.data.filters.floor_first = false;
            this.data.filters.floor_middle = false;
            this.data.filters.floor_last = false;
            this.data.filters.realtor = null;
                this.data.filters.department = false;
                this.data.filters.sold = false;
                this.data.filters.closed = false;
                this.data.filters.search = '';
                this.data.filters.date_updated = [];
                this.data.filters.date_created = [];
                this.data.filters.squaring_total = [];
                this.data.filters.squaring_kitchen = [];
                this.data.filters.ind_heating = null;
                this.data.filters.material = null;
                this.data.filters.joint = false;
                this.data.filters.ex = false;
                this.data.filters.nice = false;
                this.data.filters.status = 0;
                this.data.filters.whose = 0;
                this.sendFilters();

        }

    },
    mounted() {
        //this.getFiltersDistricts();
        this.sendFilters();
    }
}

</script>

<style scoped>

</style>
