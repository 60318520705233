import { createStore } from 'vuex'

const store = createStore({
    state () {
        return {
            buildingId: null,
            ClientId: null,
            dataDistricts: [],
            dataRepairs: [],
            dataRescomplexes: [],
            dataTypes: [],
            dataUsers: [],
            dataMaterials: [],
            dataHeatings: [],
            dataSources: [],
            dataRooms: [],
            modalLoadind: false,
            filterData: {
                filters: {
                    search: '',
                    type: [],
                    rooms: [],
                    repair: [],
                    districts: [],
                    reverse: false,
                    price: [0, 150000],
                    price_max: true,
                    floor: [1, 20],
                    squar: [10, 200],
                    floor_first: false,
                    floor_middle: false,
                    floor_last: false,
                    realtor: null,
                    department: false,
                    sold: false,
                    closed: false,
                    date_updated: [],
                    date_created: [],
                    squaring_total: [],
                    squaring_kitchen: [],
                    ind_heating: null,
                    material: null,
                    joint: false,
                    ex: false,
                    nice: false,
                    status: 0,
                    whose: 0
                },
                filter_results: 1,
                sort: "price",
                sort2: "price",
                direction: "ASC",
                page: 1,

            },
            filterDataClient: {
                filters: {
                    search: '',

                    created_a: "",
                    districts: [],
                    rooms: [],
                    type: [],
                    repair: [],
                    floor_first: false,
                    floor_middle: false,
                    floor_last: false,
                    stage_favourite: false,
                    stage_adequate: false,
                    stage_dreamer: false,
                    material_panel: false,
                    material_brick: false,
                    repair_with: false,
                    repair_ls: false,
                    type_vtor: false,
                    status: 0
                },
                filter_results: 2,
                sort: "updated_at",
                sort2: "updated_at",
                direction: "ASC",
                page: 1,
            },

            //loginData: false,
            //loginId: null,
            loginName: null,
            uSuccess: false,
            errorsModal: false,
            baseUrl:'https://baza.org.ua/',
        }
    },

})
    //$store.state.baseUrl;
    //$store.state.errorsModal;
    //$store.state.filterData;
    //$store.state.buildingId;
    //$store.state.loginData;
    //$store.state.loginDataId;
export default store;
