<template>

    <div class="modal fade" id="Modal" tabindex="-1" aria-labelledby="ModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">

                    <div class="modal-header-info" v-if="data.id">
                        <span class="modal-header-data">{{ data.created_at ? this.formatData(data.created_at) : '' }}</span>
                        <span>{{ data.id ? 'ID: '+data.id : '' }}</span>
                    </div>
                    <button type="button" id="close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    v-on:click="setDataDefault()"></button>
                </div>
                <div class="modal-body">


                    <div class="row">
                        <div class="=col-md-12 col-lg-4 col-xl-4">


                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="userName"
                                       v-model="data.name"
                                       v-bind:class="{ hasError: hasErrorName }"
                                >
                                <label for="userName">Ім'я</label>
                            </div>
                        </div>
                        <div class="=col-md-12 col-lg-4 col-xl-4">
                            <div class="form-floating mb-3">
                                <input type="email" class="form-control" id="userEmail"
                                       v-model="data.email"
                                       v-bind:class="{ hasError: hasErrorEmail }"
                                >
                                <label for="userEmail">Email</label>
                            </div>
                        </div>
                        <div class="=col-md-12 col-lg-4 col-xl-4">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="userPhone"
                                       v-model="data.phone"
                                       v-bind:class="{ hasError: hasErrorPhone }"
                                >
                                <label for="userPhone">Телефон</label>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="=col-md-12 col-lg-4 col-xl-4">
                            <div class="form-floating mb-3">
                                <input type="password" class="form-control" id="userPassword"
                                       v-model="data.password"
                                       v-bind:class="{ hasError: hasErrorPassword }"
                                >
                                <label for="userPassword">Пароль</label>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="=col-md-12 col-lg-4 col-xl-4">
                            <div class="form-floating mb-3">
                                <input type="password" class="form-control" id="userConfirmPassword"
                                       v-model="data.password_confirmation"
                                       v-bind:class="{ hasError: hasErrorPassword }"
                                >
                                <label for="userConfirmPassword">Підтвердіть пароль</label>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="=col-md-12 col-lg-3 col-xl-3">
                            <div class="obj-form-row">
                                <select class="form-select" name="status" v-model="data.role">
                                    <option :value=2>Адміністратор</option>
                                    <option v-bind:selected="true" :value=3>Ріелтор</option>

                                </select>
                            </div>
                        </div>
                    </div>

                </div>


                <div class="modal-footer">

                    <div class="row">
                        <div class="col-6 col-md-4 col-lg-3 col-xl-3 d-flex align-items-center p-0 order-1 order-lg-1">
                            <div v-if="!data.id"></div>
                            <div v-if="data.id" class="del-box">
                                <button :disabled="!delCheck" type="button" class="btn btn-danger" v-on:click="delUser(data.id)">Видалити</button>
                                <div class="form-check form-switch">
                                    <input class="form-check-input del-valid" type="checkbox" v-model="delCheck">
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-12 col-lg-5 col-xl-5 d-flex align-items-center p-0 order-0 order-lg-1 marg-dl">

                        </div>
                        <div class="col-3 col-md-4 col-lg-2 col-xl-2 d-flex align-items-center p-0 order-1 order-lg-1">

                        </div>
                        <div class="col-3 col-md-4 col-lg-2 col-xl-2 d-flex justify-content-end p-0 order-1 order-lg-1">
                            <button type="button" class="btn btn-primary" v-on:click="data.id ? updateUser() : createUser()">Зберегти</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</template>

<script>



import moment from "moment";
//var moment = require('moment'); // require

export default {
    name: "AdmObjModal",
    components: {


    },
    data() {
        return {
            enabled: false,

            id: null,
            delCheck: false,

            hasErrorName: false,
            hasErrorEmail: false,
            hasErrorPassword: false,
            hasErrorRole: false,
            hasErrorPhone: false,


            data: {
                id: null,
                name: null,
                email: null,
                password: null,
                password_confirmation: null,
                role: 3,
                phone: null,
            }
        }
    },
    methods: {
        async createUser() {
            //start animation
            try {
                const response = await this.axios.post('/api/users/create', this.data);
                this.resetError();
                alert('Добавлено');
                this.getUserInfo(response.data.id);
                this.refreshList();
            } catch (err) {
                const {data, status} = err.response;
                if (status === 422) {
                    this.getError(data.errors);
                }
            }

        },
        async updateUser() {
            this.axios
                try {
                    await this.axios.post('/api/users/edit', this.data);
                    this.resetError();
                    alert('Збережено');
                    this.getUserInfo(this.data.id);
                    this.refreshList();
                } catch (err) {
                    console.log(err)
                    const {data, status} = err.response;
                    if (status === 422) {
                        this.getError(data.errors);
                    }
                }
        },

        getError(error) {

            this.resetError();

            if (error.name) {
                this.hasErrorName = true;
            }
            if (error.email) {
                this.hasErrorEmail = true;
            }
            if (error.password) {
                this.hasErrorPassword = true;
            }
            if (error.password_confirmation) {
                this.hasErrorPassword = true;
            }
            if (error.role) {
                this.hasErrorRole = true;
            }
            if (error.phone) {
                this.hasErrorPhone = true;
            }

        },
        resetError(){
            this.hasErrorName = false;
            this.hasErrorEmail = false;
            this.hasErrorPassword = false;
            this.hasErrorRole = false;
            this.hasErrorPhone = false;
        },


        getUserInfo(id) {

            this.resetError();

            this.axios
                .get('/api/users/'+id)
                .then((response) => {
                    this.dataUser = response.data.data;
                    this.data = this.dataUser;
                    this.delCheck= false;
                    this.$store.state.UserId = id;
                })
                .catch((error) => {
                    console.log(error);
                })
        },


        delUser(id) {
            this.axios
                .get('/api/users/del/'+id)
                .then(() => {
                    alert("Користувача видалено");
                    document.getElementById('close').click();
                    this.refreshList();
                })
                .catch((error) => {
                    console.log(error);
                })
        },

        refreshList() {
            this.$emit('sendRefresh');
        },
        formatData(date) {
            moment.locale('uk');
            return moment(date, "YYYYMMDD").format('DD MM YYYY, h:mm');
        },
        setDefault() {
            this.data.id = null;
            this.data.name = null;
            this.data.email = null;
            this.data.password = null;
            this.data.password_confirmation = null;
            this.data.role = null;
            this.data.phone = null;

            this.resetError();

        },

    },
    mounted() {

    },
    beforeMount(){

    }

}


</script>

<style scoped>

</style>




