<template>
    <div class="container-fluid">
        <div id="filters">

            <div class="filters-grup-2">
                <div class="filter_rooms">
                    <div class="orang">
                        <input type="checkbox" class="inp-checkbox-btn" id="filter_rooms-1"
                               :value="1" v-model="data.filters.rooms" @change="sendFilters">
                        <label class="lbl-checkbox-btn" for="filter_rooms-1">1 кім</label>
                    </div>
                    <div class="orang">
                        <input type="checkbox" class="inp-checkbox-btn" id="filter_rooms-2"
                               :value="2" v-model="data.filters.rooms" @change="sendFilters">
                        <label class="lbl-checkbox-btn" for="filter_rooms-2">2 кім</label>
                    </div>
                    <div class="orang">
                        <input type="checkbox" class="inp-checkbox-btn" id="filter_rooms-3"
                               :value="3" v-model="data.filters.rooms" @change="sendFilters">
                        <label class="lbl-checkbox-btn" for="filter_rooms-3">3 кім</label>
                    </div>
                    <div class="orang">
                        <input type="checkbox" class="inp-checkbox-btn" id="filter_rooms-4"
                               :value="4" v-model="data.filters.rooms" @change="sendFilters">
                        <label class="lbl-checkbox-btn" for="filter_rooms-4">4 кім</label>
                    </div>

                </div>


                <div class="filter_type">
                    <div class="green">
                        <input type="checkbox" class="inp-checkbox-btn" id="type-1"
                               :value="1" v-model="data.filters.type" @change="sendFilters">
                        <label class="lbl-checkbox-btn" for="type-1">НБ з ремонтом</label>
                    </div>
                    <div class="green">
                        <input type="checkbox" class="inp-checkbox-btn" id="type-2"
                               :value="2" v-model="data.filters.type" @change="sendFilters">
                        <label class="lbl-checkbox-btn" for="type-2">НБ здана</label>
                    </div>
                    <div class="green">
                        <input type="checkbox" class="inp-checkbox-btn" id="type-3"
                               :value="3" v-model="data.filters.type" @change="sendFilters">
                        <label class="lbl-checkbox-btn" for="type-3">НБ будівництво</label>
                    </div>
                    <div class="green">
                        <input type="checkbox" class="inp-checkbox-btn" id="type-99"
                               :value="99" v-model="data.filters.type" @change="sendFilters">
                        <label class="lbl-checkbox-btn" for="type-99">Вторинний</label>
                    </div>

                </div>





                <div class="filter_type">
                    <div class="magenta">
                        <input type="checkbox" class="inp-checkbox-btn" id="type-15"
                               :value="15" v-model="data.filters.type" @change="sendFilters">
                        <label class="lbl-checkbox-btn" for="type-15">Комерція</label>
                    </div>
                    <div class="magenta">
                        <input type="checkbox" class="inp-checkbox-btn" id="type-16"
                               :value="16" v-model="data.filters.type" @change="sendFilters">
                        <label class="lbl-checkbox-btn" for="type-16">Будинок</label>
                    </div>
                    <div class="magenta">
                        <input type="checkbox" class="inp-checkbox-btn" id="type-17"
                               :value="17" v-model="data.filters.type" @change="sendFilters">
                        <label class="lbl-checkbox-btn" for="type-17">Земля</label>
                    </div>
                    <div class="magenta">
                        <input type="checkbox" class="inp-checkbox-btn" id="type-18"
                               :value="18" v-model="data.filters.type" @change="sendFilters">
                        <label class="lbl-checkbox-btn" for="type-18">Оренда</label>
                    </div>
                    <div class="magenta">
                        <input type="checkbox" class="inp-checkbox-btn" id="type-19"
                               :value="19" v-model="data.filters.type" @change="sendFilters">
                        <label class="lbl-checkbox-btn" for="type-19">Кімната</label>
                    </div>
                </div>



            </div>
            <div class="filters-grup-3">



                <div class="filter_districts">
                    <div v-for="district in this.$store.state.dataDistricts" :key="district.id" class="blue">
                        <input type="checkbox"
                               class="inp-checkbox-btn"
                               v-bind:id="'filter_district-'+ district.id"
                               :value="district.id"
                               v-model="data.filters.districts"
                               @change="sendFilters"
                        >
                        <label class="lbl-checkbox-btn"
                               v-bind:for="'filter_district-'+ district.id">{{ district.name }}
                        </label>
                    </div>
                </div>

            </div>
            <div class="filters-grup-3">




                <div class="green">
                    <input type="checkbox" class="inp-checkbox-btn" id="filter_repair_with"
                           :value="1" v-model="data.filters.repair_with" @change="sendFilters">
                    <label class="lbl-checkbox-btn" for="filter_repair_with">З ремонтом</label>
                    <input type="checkbox" class="inp-checkbox-btn" id="filter_repair_ls"
                           :value="1" v-model="data.filters.repair_ls" @change="sendFilters">
                    <label class="lbl-checkbox-btn" for="filter_repair_ls">Ж.С.</label>
                </div>

                <div class="filter_type orang">
                    <div>
                        <input type="checkbox" class="inp-checkbox-btn" id="filter_floor_first"
                               value=true v-model="data.filters.floor_first" @change="sendFilters">
                        <label class="lbl-checkbox-btn" for="filter_floor_first">Перший</label>
                    </div>
                    <div>
                        <input type="checkbox" class="inp-checkbox-btn" id="filter_floor_middle"
                               value=true v-model="data.filters.floor_middle" @change="sendFilters">
                        <label class="lbl-checkbox-btn" for="filter_floor_middle">Середній</label>
                    </div>
                    <div>
                        <input type="checkbox" class="inp-checkbox-btn" id="filter_floor_last"
                               value=true v-model="data.filters.floor_last" @change="sendFilters">
                        <label class="lbl-checkbox-btn" for="filter_floor_last">Останній</label>
                    </div>
                </div>


                <div class="blue">
                    <input type="checkbox" class="inp-checkbox-btn" id="filter_material_panel"
                           :value="1" v-model="data.filters.material_panel" @change="sendFilters">
                    <label class="lbl-checkbox-btn" for="filter_material_panel">Панель</label>
                    <input type="checkbox" class="inp-checkbox-btn" id="filter_material_brick"
                           :value="1" v-model="data.filters.material_brick" @change="sendFilters">
                    <label class="lbl-checkbox-btn" for="filter_material_brick">Цегла</label>
                </div>



            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "AdmCliFilters",

    data() {
        return {
            districtsbtn: [],
            data: {
                filters: {
                    price: [0, 150000],
                    created_at: '',

                    districts: [],

                    rooms: [],
                    type: [],
                    repair: [],
                    search: '',

                    floor_first: false,
                    floor_middle: false,
                    floor_last: false,

                    stage_favourite: false,
                    stage_adequate: false,
                    stage_dreamer: false,

                    material_panel: false,
                    material_brick: false,

                    repair_with: false,
                    repair_ls: false,

                    type_vtor: false,

                    status: 0,
                },
                filter_results: 1,
                sort: 'updated_at',
                sort2: 'updated_at',
                direction: 'ASC',
                page: 1
            }
        }
    },
    methods: {
        sendFilters() {
            this.$store.state.filterDataClient = this.data;
            //console.log(this.data);
            this.$emit('dataFilters', this.data);
        },
        getFiltersDistricts() {
            this.axios
                .post('/api/districts')
                .then((response) => {
                    this.districtsbtn = response.data.data;
                })
                .catch((error) => {
                    console.log(error.response.data);
                })
        },
    },
    mounted() {
        //this.getFiltersDistricts();
        this.sendFilters();
    }
}

</script>

<style scoped>

</style>
