<template>
    <AdmHeader @dataDefaults="getDefault" />
    <AdmObjFilters @dataFilters="getFilters" />
    <AdmObjList ref="refList" />
    <AdmFooter />

</template>

<script>

import AdmHeader from './AdmHeader.vue'
import AdmObjFilters from './AdmObjFilters.vue'
import AdmObjList from './AdmObjList.vue'
import AdmFooter from "@/components/AdmFooter";

export default {
    name: "AdmBuildings",
    components: {
        AdmHeader,
        AdmObjFilters,
        AdmObjList,
        AdmFooter,
    },
    data() {
        return {

        }
    },
    methods: {
        getFilters(filters) {
            this.$refs.refList.getBuildings(filters);

        },
        getDefault() {
            this.$refs.refList.setDefault();
        },
    },
    mounted() {
        //this.checkLogin();
    }
}

</script>

<style scoped>

</style>
