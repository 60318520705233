<template>
    <div class="container-fluid">
        <div class="header navbar-light">
            <div class="head-left">

                        <button class="navbar-toggler"
                                type="button"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasNavbar"
                                aria-controls="offcanvasNavbar">
                            <span class="navbar-toggler-icon"></span>
                        </button>


                        <div class="header-tabs">
                            <router-link to="/customers">Клієнти</router-link>
                            <router-link to="/buildings">Об'єкти</router-link>

                            <router-link  to="/users" v-if="this.$store.state.uSuccess" class="link_supp">Користувачі</router-link>
                            <router-link to="/delete-objects" v-if="this.$store.state.uSuccess" class="link_supp">Видалені об'єкти</router-link>
                            <router-link to="/delete-customers" v-if="this.$store.state.uSuccess" class="link_supp">Видалені клієнти</router-link>

                        </div>

            </div>


                    <div class="head-add-butt"
                        data-bs-toggle="modal" data-bs-target="#Modal"
                        v-on:click="sendDefault">
                        <div>+
                        <span>&nbsp;Додати</span></div>
                    </div>


                <div class="head-acc">
                    <span>{{this.$store.state.loginName}}&nbsp;</span>
                    <a href="#" v-on:click="logout()">Вийти</a>
                </div>



                    <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">

                        <div class="offcanvas-header">
                            <h5 class="offcanvas-title" id="offcanvasNavbarLabel">Menu</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>

                        <div class="offcanvas-body">
                            <router-link to="/customers" @click="toggleMenu">Клієнти</router-link>
                            <router-link to="/buildings" @click="toggleMenu">Об'єкти</router-link>
                            <router-link to="/users" @click="toggleMenu" v-if="this.$store.state.uSuccess">Користувачі</router-link>
                            <router-link to="/delete-objects" @click="toggleMenu" v-if="this.$store.state.uSuccess">Видалені об'єкти</router-link>
                            <router-link to="/delete-customers" @click="toggleMenu" v-if="this.$store.state.uSuccess">Видалені клієнти</router-link>
                        </div>

                    </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "AdmHeader",
    myOffCanvas: null,
    data() {
        return {

        }
    },
    methods: {
        logout() {
            this.axios
                .post('/logout')
                //.then(function () {
                .then(() => {
                    localStorage.removeItem('x_xsrf_token');
                    //this.$store.state.loginId = null;
                    this.$router.push({ name: 'login' });
                    //this.$router.push({ name: 'login', replace: true });
                })
                .catch(err => {
                    console.log(err.response);
                })
        },
        toggleMenu() {
            const body = document.getElementsByTagName('body')[0];
            body.removeAttribute("style");
        },
        sendDefault() {
            this.$emit('dataDefaults');
        },

        checkLogin() {
            this.axios
                .post('/api/user/get')
                .then((response) => {
                    //this.loginData = response;
                    //this.$store.state.loginData = response.data;
                    //this.$store.state.loginId = response.data.id;

                    this.$store.state.loginName = response.data.name;

                    if (response.data.role <= 2) {
                        this.$store.state.uSuccess = true;
                    } else {
                        this.$store.state.uSuccess = false;
                    }
                })
                .catch(() => {

                })
        },
        checkMeta() {

            if (this.$store.state.dataDistricts.length === 0) {
                this.getMeta();
            }
        },
        getMeta() {
            this.axios
                .post('/api/meta')
                .then((response) => {
                    this.$store.state.dataDistricts = response.data.meta.districts;

                    this.$store.state.dataRepairs = response.data.meta.repairs;
                    this.$store.state.dataRescomplexes = response.data.meta.rescomplexes;
                    this.$store.state.dataTypes = response.data.meta.types;
                    this.$store.state.dataUsers = response.data.meta.users;

                    this.$store.state.dataMaterials = response.data.meta.material;
                    this.$store.state.dataHeatings = response.data.meta.heating;
                    this.$store.state.dataSources = response.data.meta.source;
                    this.$store.state.dataRooms = { "1" : 1, "2" : 2, "3" : 3, "4" :4, "5" : 5 };
                })
                .catch((error) => {
                    console.log(error.response.data.meta);
                })
        },

    },


    mounted() {
        this.checkLogin();

        this.checkMeta();
    }
}
</script>

<style scoped>


</style>
