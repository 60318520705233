<template>
    <AdmHeader @dataDefaults="getDefault" />

    <div class="container-fluid">
        <div id="content" class="mg-top-5">
            <div class="object-list">
                <div class="object-list-head">


                    <div class="list-item--image">Фот</div>
                    <div class="item-info">
                        <div class="list-item--room">Кім</div>
                        <div class="list-item--price">
                            <div class="sort">
                                <input class="inp-radiobox-btn" type="radio" id="sort_p"
                                       v-model="this.$store.state.filterData.sort2"
                                       value="price"
                                       @click="setSortDirection">
                                <label class="form-radiobox-label" for="sort_p">Ціна<span>{{arrow}}</span></label>
                            </div>
                        </div>
                        <div class="list-item--district">Район</div>
                        <div class="list-item--floor">
                            <div class="sort">
                                <input class="inp-radiobox-btn" type="radio" id="sort_f"
                                       v-model="this.$store.state.filterData.sort2"
                                       value="floor"
                                       @click="setSortDirection">
                                <label class="form-radiobox-label" for="sort_f">П<span>{{arrow}}</span></label>
                            </div>
                        </div>
                        <div class="list-item--max_floor">Пов</div>
                        <div class="list-item--type">Планування</div>
                        <div class="list-item--squar">
                            <div class="sort">
                                <input class="inp-radiobox-btn" type="radio" id="sort_s"
                                       v-model="this.$store.state.filterData.sort2"
                                       value="squaring_total"
                                       @change="getBuildings">
                                <label class="form-radiobox-label" for="sort_s">Площа<span>{{arrow}}</span></label>
                            </div>
                        </div>
                        <div class="list-item--descr_h">Опис</div>
                        <div class="list-item--realt">Ріелтор</div>
                        <div class="list-item--phone">Телефон</div>
                        <div class="list-item--upd">
                            <div class="sort">
                                <input class="inp-radiobox-btn" type="radio" id="sort_u"
                                       v-model="this.$store.state.filterData.sort2"
                                       value="created_at"
                                       @click="setSortDirection">
                                <label class="form-radiobox-label" for="sort_u">Оновл<span>{{arrow}}</span></label>
                            </div>
                        </div>
                        <div class="list-item--creat">
                            <div class="sort">
                                <input class="inp-radiobox-btn" type="radio" id="sort_c"
                                       v-model="this.$store.state.filterData.sort2"
                                       value="updated_at"
                                       @click="setSortDirection">
                                <label class="form-radiobox-label" for="sort_c">Створ<span>{{arrow}}</span></label>
                            </div>
                        </div>
                    </div>
                    <div class="list-item--id">ID</div>
                </div>

                <div class="object-list-add-loading" v-show="loading">
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                </div>

                <div class="object-item" v-for="(building) in buildings"
                     :key="building.id"
                     v-bind:class="{ linecolornice: building.nice, linecolorex: building.ex }"
                     v-show="list">

                    <div class="list-item--image" v-on:click.self.prevent>
                        <div  v-if="building.image" class="item--image">
                            <img v-bind:src="'https://baza.org.ua/'+building.image.path_preview" @click="startFancy(building.images)"/>
                        </div>
                    </div>

                    <div class="item-info" v-on:click="modalInfo(building.id)" data-bs-toggle="modal" data-bs-target="#Modal">
                        <div class="list-item--room">{{ building.rooms }}</div>
                        <div class="list-item--price">{{ building.price }}</div>
                        <div class="list-item--district">{{ building.district ? building.district.name : '' }}</div>
                        <div class="list-item--floor">{{ building.floor }}</div>
                        <div class="list-item--max_floor">{{ building.max_floor }}</div>
                        <div class="list-item--type">{{ building.type ? building.type.name : '' }}</div>
                        <div class="list-item--squar">{{ building.squaring_total }} м2</div>

                        <div class="list-item--descr">{{ building.description }}</div>

                        <div class="list-item--realt" v-if="building.realtor_id === 98" style="color:#1c70e0;">{{ building.custom_realtor }}</div>
                        <div class="list-item--realt" v-else >{{ building.custom_realtor }}</div>

                        <div class="list-item--phone" v-if="building.realtor_id === 98" style="color:#1c70e0;">{{ building.custom_realtor_phone }}</div>
                        <div class="list-item--phone" v-else >{{ building.custom_realtor_phone }}</div>

                        <div class="list-item--upd">{{ this.formatData(building.updated_at) }}</div>
                        <div class="list-item--creat">{{ this.formatData(building.created_at) }}</div>
                        <div class="list-item--id">{{ building.id }}</div>
                    </div>

                </div>
            </div>

            <div class="object-list-add-loading" v-show="loadingScroll">
                <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
            </div>

        </div>
    </div>
    <AdmObjModal ref="refModal" @sendRefresh="getTrashBuildings" />
    <AdmFooter />
</template>

<script>

import AdmHeader from './AdmHeader.vue'
import moment from "moment";
import AdmObjModal from './AdmObjModal.vue'
import AdmFooter from "@/components/AdmFooter";
import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox";

export default {
    name: "AdmDeleteObjects",
    components: {
        AdmHeader,
        AdmObjModal,
        AdmFooter,
    },
    data() {
        return {
            loading: false,
            list: true,
            loadingScroll: false,
            empty: false,
            buildings: [],
            gallerys: [],
            lineColor: 1,
            arrow : '🠅',
            dataId: {
                action: null,
                ids: [],
            },
            data: {
                filters: {
                    type: []
                },
                filter_results: 1,
                sort: 'price',
                direction: 'ASC',
                page: 1
            }
        }
    },
    methods: {
        startFancy(images) {

            let gallery = new Array();
            images.forEach(function(element, index) {
                gallery[index] = {src: 'https://baza.org.ua/'+element.path};
            });

            Fancybox.show(gallery, {}); //starts fancybox with the gallery object
        },
        getTrashBuildings() {

            this.loading = true;
            this.list = false;

            this.$store.state.filterData.page = 1;
            this.axios
                .post('/api/buildings/trash', this.$store.state.filterData)
                .then((response) => {
                    this.buildings = response.data.data;
                    document.addEventListener('scroll', this.handleScroll);

                    if (response.data.data.length === 0) {
                        this.empty = true;
                    } else {
                        this.empty = false;
                    }
                    this.loading = false;
                    this.list = true;
                })
                .catch((error) => {
                    console.log(error);
                })
        },
        getTrashBuildingsPlus() {
            this.loadingScroll = true;

            this.axios
                .post('/api/buildings/trash', this.$store.state.filterData)
                .then((response) => {
                    if (response.data.data.length > 0) {
                        response.data.data.forEach(element => this.buildings.push(element));
                    } else {
                        this.loadingScroll = false;
                        this.empty = true;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        },
        handleScroll () {
          if (this.empty === false) {
            if(window.scrollY+1 >= document.body.scrollHeight - window.innerHeight) {
                this.$store.state.filterData.page++;
                this.getTrashBuildingsPlus();
            }
          }
        },
        modalInfo(id) {
            this.$refs.refModal.getBuildInfo(id);
        },
        getDefault() {
            this.$refs.refList.setDefault();
        },
        checkLogin() {
            this.axios
                .post('/api/user/get')
                .then((response) => {
                    this.loginData = response;
                    this.$store.state.loginData = response.data;
                    this.$store.state.loginId = response.data.id;
                })
                .catch(() => {
                })
        },
        formatData(date) {
            moment.locale('uk');
            return moment.utc(date).fromNow();
        },
        setSort() {
            //this.getBuildings();
        },
        setSortDirection(event) {
            if (this.$store.state.filterData.sort === event.target.value) {
                this.$store.state.filterData.sort = event.target.value
                if (this.$store.state.filterData.direction === 'ASC') {
                    this.$store.state.filterData.direction = 'DESC'
                    this.arrow = '🠇'
                } else {
                    this.$store.state.filterData.direction = 'ASC'
                    this.arrow = '🠅'
                }
                this.getTrashBuildings();
            } else {
                this.$store.state.filterData.sort = event.target.value
                this.$store.state.filterData.direction = 'ASC'
                this.arrow = '🠅';
                this.getTrashBuildings();
            }
        },

    },
    mounted() {
        //this.checkLogin();
        this.getTrashBuildings();

    }
}

</script>

<style scoped>

</style>
