<template>
    <router-view></router-view>
</template>

<script>

export default {
  name: 'App',

  components: {

  },
    data() {
        return {
            loginData: [],
        }
    },
    computed: {

    },
    methods: {

        getDistricts() {
            this.axios
                .post('/api/districts')
                .then((response) => {
                    this.$store.state.dataDistricts = response.data.data;
                })
                .catch((error) => {
                    console.log(error.response.data);
                })
        },
        getMeta() {
            this.axios
                .post('/api/meta')
                .then((response) => {
                    this.$store.state.dataDistricts = response.data.meta.districts;

                    this.$store.state.dataRepairs = response.data.meta.repairs;
                    this.$store.state.dataRescomplexes = response.data.meta.rescomplexes;
                    this.$store.state.dataTypes = response.data.meta.types;
                    this.$store.state.dataUsers = response.data.meta.users;

                    this.$store.state.dataMaterials = response.data.meta.material;
                    this.$store.state.dataHeatings = response.data.meta.heating;
                    this.$store.state.dataSources = response.data.meta.source;
                    this.$store.state.dataRooms = { "1" : 1, "2" : 2, "3" : 3, "4" :4, "5" : 5 };


                })
                .catch((error) => {
                    console.log(error.response.data.meta);
                })
        },

/*
      //////////////////hz
        checkLogin() {
            this.axios
                .post('/api/user/get')
                .then((response) => {
                    //this.loginData = response;
                    //this.$store.state.loginData = response.data;
                    //this.$store.state.loginId = response.data.id;

                    if (response.data.role === 1) {
                        this.$store.state.uSuccess = true;
                    } else {
                        this.$store.state.uSuccess = false;
                    }
                })
                .catch(() => {

                })
        },
        //////////////////hz
*/
    },
    mounted() {
        //////////////////hz
        //this.checkLogin();
        //////////////////hz
        //this.getDistricts();

        //this.getMeta();


        this.$router.push({ name: 'login' });
    }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*margin-top: 60px;*/
}
</style>

