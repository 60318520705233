<template>

    <div class="modal fade" id="Modal" tabindex="-1" aria-labelledby="ModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-loading"  v-if="this.$store.state.modalLoadind">
                <div class="spinner-border" role="status">
                    <span class="sr-only"></span>
                </div>
            </div>
            <div class="modal-content">
                <div class="modal-header">
                    <div class="modal-header-info" v-if="data.id">
                        <span>{{ data.author ? 'Автор: '+data.author.name : '' }}</span>
                        <span class="modal-header-data">{{ data.created_at ? this.formatData(data.created_at) : '' }}</span>
                        <span>{{ data.id ? 'ID: '+data.id : '' }}</span>
                    </div>
                    <button type="button" id="close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    v-on:click="setDataDefault()"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="=col-md-12 col-lg-5 col-xl-6">
                            <div class="obj-form-row">
                                <select class="form-select" name="realtor" id="realtor"
                                        v-model="data.realtor_id"
                                        @click="pushRealtor()"
                                        v-bind:class="{ hasError: hasErrorRealtor }">
                                    <option value="null" disabled>Рієлтор</option>
                                    <option value="98">Партнерка</option>
                                    <option v-for="usersAgent in this.$store.state.dataUsers" :key="usersAgent.id"
                                            v-bind:selected="data.realtor_id === usersAgent.id"
                                            v-bind:value="usersAgent.id">
                                        {{ usersAgent.name }}
                                    </option>
                                    <option value="99">Забудовник</option>
                                </select>

                                <select class="form-select" name="district" v-model="data.district_id"
                                        v-bind:class="{ hasError: hasErrorDistrict }">
                                    <option selected value="null">Район</option>
                                    <option v-for="district in this.$store.state.dataDistricts" :key="district.id"
                                            v-bind:selected="data.district_id === district.id"
                                            v-bind:value="district.id">
                                        {{ district.name }}
                                    </option>
                                </select>

                                <input type="text" class="form-control"
                                       v-model.number="data.price"
                                       placeholder="Ціна"
                                       v-bind:class="{ hasError: hasErrorPrice }">
                            </div>
                            <div class="obj-form-row">

                                <select class="form-select obj-form--room" name="room"
                                        v-model="data.rooms"
                                        v-bind:class="{ hasError: hasErrorRooms }">
                                    <option selected value="null">Кімнат</option>
                                    <option v-for="(room, idn) in this.$store.state.dataRooms" :key="room.id"
                                            v-bind:selected="data.room === idn"
                                            v-bind:value="idn">
                                        {{ room }}
                                    </option>
                                </select>

                                <input type="text" class="form-control obj-form--floor"
                                       v-model.number="data.floor"
                                       placeholder="Поверх"
                                       v-bind:class="{ hasError: hasErrorFloor }">

                                <input type="text" class="form-control obj-form--floor_max"
                                       v-model="data.max_floor"
                                       placeholder="Поверховість"
                                       v-bind:class="{ hasError: hasErrorFloormax }">

                                <select class="form-select" name="type" v-model="data.type_id"
                                        v-bind:class="{ hasError: hasErrorType }">
                                    <option selected value="null">Планування</option>
                                    <option v-for="type in this.$store.state.dataTypes" :key="type.id"
                                            v-bind:selected="data.type_id === type.id"
                                            v-bind:value="type.id">
                                        {{ type.name }}
                                    </option>
                                </select>

                            </div>
                        </div>
                        <div class="=col-md-12 col-lg-7 col-xl-6">
                            <div class="modal-info-top">
                                <div class="modal-info-realt">
                                    <span>Ріелтор:</span>
                                    <input type="text" class="form-control"
                                           v-model="data.custom_realtor"
                                           placeholder="Ріелтор"
                                           v-bind:class="{ hasError: hasErrorCustomRealtor }">
                                    <input type="text" class="form-control"
                                           v-model="data.custom_realtor_phone"
                                           placeholder="Телефон"
                                           v-bind:class="{ hasError: hasErrorCustomRealtorPhone }">
                                </div>
                                <div class="modal-info-own">
                                    <span>Власник:</span>
                                    <input type="text" class="form-control"
                                           v-model="data.owner"
                                           placeholder="Власник"
                                           v-bind:class="{ hasError: hasErrorOwner }">
                                    <input type="text" class="form-control"
                                           v-model="data.owner_phone"
                                           placeholder="Телефон"
                                           v-bind:class="{ hasError: hasErrorOwnerPhone }">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="=col-md-12 col-lg-6 col-xl-6">
                            <div class="obj-form-row">

                                <select class="form-select" name="material" v-model="data.material">
                                    <option value="null">Матеріал</option>
                                    <option v-for="(material, idn) in this.$store.state.dataMaterials" :key="material.id"
                                            v-bind:selected="data.material === idn"
                                            v-bind:value="idn">
                                        {{ material }}
                                    </option>
                                </select>

                                <input type="text" class="form-control"
                                       v-model="data.squaring_total"
                                       placeholder="Заг">

                                <input type="text" class="form-control"
                                       v-model="data.squaring_kitchen"
                                       placeholder="Кухня">


                                <select class="form-select" name="heating" v-model="data.heating">
                                    <option value="null" class="default">Опалення</option>
                                    <option v-for="(heating, idn) in this.$store.state.dataHeatings" :key="heating.id"
                                            v-bind:selected="data.heating === idn"
                                            v-bind:value="idn">
                                        {{ heating }}
                                    </option>
                                </select>

                            </div>
                        </div>
                        <div class="=col-md-12 col-lg-6 col-xl-6">
                            <div class="obj-form-row">


                                <input type="text" class="form-control"
                                       v-model="data.address"
                                       placeholder="Адреса">
                                <input type="text" class="form-control"
                                       v-model="data.mark"
                                       placeholder="Орієнтир">
                                <input type="text" class="form-control"
                                       v-model="data.href"
                                       placeholder="Посилання">

                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-5">
                            <div class="form-floating">
                                    <textarea class="form-control"
                                              id="description"
                                              placeholder="Опис"
                                              v-model="data.description"
                                              style="height: 100px"
                                              v-bind:class="{ hasError: hasErrorDescription }"
                                    ></textarea>
                                <label for="description">Опис</label>
                            </div>
                        </div>
                        <div class="col-md-4">

                            <div class="form-floating">
                                    <textarea class="form-control"
                                              id="note"
                                              placeholder="Примітка"
                                              v-model="data.note"
                                              style="height: 100px">

                                    </textarea>
                                <label for="note">Примітка</label>
                            </div>


                        </div>
                        <div class="col-md-3">

                            <div style="text-align: left;">
                                <input type="checkbox" :disabled="!selfObj" class="inp-checkbox-def" id="ex"
                                       :value="1"
                                       v-model="data.ex"
                                       :checked="data.ex">
                                <label class="lbl-checkbox-def" for="ex">Екс</label>
                                <br>
                                <input type="checkbox" class="inp-checkbox-def" id="nice"
                                       :value="1"
                                       v-model="data.nice"
                                       :checked="data.nice">
                                <label class="lbl-checkbox-def" for="nice">Цікаве</label>
                            </div>

                        </div>
                    </div>


                    <div class="row">

                        <div class="col-md-6">
                            <div class="form-floating">
                                    <textarea class="form-control"
                                              id="description_front"
                                              placeholder="Leave a comment here"
                                              v-model="data.description_front"
                                              style="height: 100px"></textarea>
                                <label for="description_front">Опис на сайт</label>
                            </div>
                        </div>

{{  }}
                        <div class="col-md-6">
                            <div class="form-history">
                                <ul>
                                    <li class="form-hist-item" v-for="chang in this.data.changes" :key="chang.id">
                                        <div class="form-hist-item-child form-hist-data">{{ this.formatData(chang.created_at) }}</div>
                                        <div class="form-hist-item-child form-hist-name">{{ chang.realtor.name }} :</div>

                                        <div class="form-hist-item-child form-hist-price" v-if="chang.price_new">
                                            <div class="form-hist-item-title">Ціна:</div>{{ chang.price_old }} -> {{ chang.price_new }}
                                        </div>

                                        <div class="form-hist-item-child form-hist-status" v-if="chang.status_new < 3">
                                            <span v-if="chang.status_old == 0">Активне -></span>
                                            <span v-else-if="chang.status_old == 1">Продане -></span>
                                            <span v-else-if="chang.status_old == 2">Знято з продажу -></span>

                                            <span v-if="chang.status_new == 0">Активне</span>
                                            <span v-else-if="chang.status_new == 1">Продане</span>
                                            <span v-else-if="chang.status_new == 2">Знято з продажу</span>

                                        </div>

                                        <div class="form-hist-item-child form-hist-owner_phone" v-if="chang.owner_phone_new">
                                            <div class="form-hist-item-title">Телефон власника:</div>
                                            {{ chang.owner_phone_old }} -> {{ chang.owner_phone_new }}
                                        </div>
                                        <div class="form-hist-item-child form-hist-owner_phone" v-else-if="chang.owner_phone_old">
                                            <div class="form-hist-item-title">Телефон власника:</div>
                                            {{ chang.owner_phone_old }} -> {{ chang.owner_phone_new }}
                                        </div>

                                        <div class="form-hist-item-child form-hist-descr" v-if="chang.description_new">
                                            <div class="form-hist-item-title">Опис:</div>
                                            <span v-b-tooltip.hover v-bind:title="chang.description_old">Було</span>
                                            ->
                                            <span v-b-tooltip.hover v-bind:title="chang.description_new">Стало</span>
                                        </div>


                                        <div class="form-hist-item-child form-hist-note" v-if="chang.note_new != ''">
                                            <div class="form-hist-item-title">Примітка: </div>
                                            <span v-b-tooltip.hover v-bind:title="chang.note_old">Було</span>
                                            ->
                                            <span v-b-tooltip.hover v-bind:title="chang.note_new">Стало</span>
                                        </div>
                                        <div class="form-hist-item-child form-hist-note" v-else-if="chang.note_old != ''">
                                            <div class="form-hist-item-title">Примітка: </div>
                                            <span v-b-tooltip.hover v-bind:title="chang.note_old">Було</span>
                                            ->
                                            <span v-b-tooltip.hover v-bind:title="chang.note_new">Стало</span>
                                        </div>


                                        <div class="form-hist-item-child form-hist-name" v-if="chang.image_path">
                                            Редагування фото
                                        </div>

                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-12 img-form">
                            <AdmObjImages ref="refImages"/>
                            <draggable v-if="delImageCheck" class="obj-images dragArea list-group w-full" :list="images">
                                <div class="obj-image-item" v-for="image in images" :key="image.id">
                                    <input type="checkbox" class="del-img del-img-checkbox"
                                           v-if="delImageCheck"
                                           v-model="del_img"
                                           :value="image.id">
                                    <a data-fancybox="gallery" v-bind:href="'https://baza.org.ua/' + image.path">
                                        <img v-bind:src="'https://baza.org.ua/' + image.path_preview"/>
                                    </a>
                                </div>
                            </draggable>
                            <div v-else class="obj-image-item" v-for="image in images" :key="image.id">
                                <input type="checkbox" class="del-img del-img-checkbox"
                                       v-if="delImageCheck"
                                       v-model="del_img"
                                       :value="image.id">
                                <a data-fancybox="gallery" v-bind:href="'https://baza.org.ua/' + image.path">
                                    <img v-bind:src="'https://baza.org.ua/' + image.path_preview"/>
                                </a>
                            </div>
                        </div>


                    </div>


                </div>

                <div class="modal-footer">

                    <div class="row" v-if="!data.trash">
                        <div class="col-6 col-md-4 col-lg-3 col-xl-3 d-flex align-items-center p-0 order-1 order-lg-1">
                            <div v-if="!data.id"></div>
                            <div v-if="data.id" class="del-box">
                                <button :disabled="!delCheck" type="button" class="btn btn-danger" v-on:click="delBuild(data.id)">Видалити</button>
                                <div class="form-check form-switch" v-if="this.$store.state.uSuccess">
                                    <input class="form-check-input del-valid" type="checkbox" v-model="delCheck">
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-12 col-lg-5 col-xl-5 d-flex align-items-center p-0 order-0 order-lg-1 marg-dl">
                            <div v-if="data.id" class="del-box-img">
                                <div class="form-check form-switch">
                                    <input class="form-check-input del-valid" type="checkbox" v-model="delImageCheck">
                                    <span>Редагувати фото</span>
                                </div>
                                <button v-if="delImageCheck" type="button" class="btn btn-danger" v-on:click="delImages()">Видалити фото</button>
                            </div>
                        </div>
                        <div class="col-3 col-md-4 col-lg-2 col-xl-2 d-flex align-items-center p-0 order-1 order-lg-1">
                            <select class="form-select" name="status" v-model="data.status">
                                <option selected :value=0>Активне</option>
                                <option :value=1>Продане</option>
                                <option :value=2>Знято з продажу</option>
                            </select>
                        </div>
                        <div class="col-3 col-md-4 col-lg-2 col-xl-2 d-flex justify-content-end p-0 order-1 order-lg-1">
                            <button type="button" class="btn btn-primary" v-on:click="data.id ? updateBuilding() : createBuilding()">Зберегти</button>
                        </div>
                    </div>
                    <div class="row" v-else>
                        <div class="col-12 col-md-12 col-lg-12 col-xl-12 align-items-center p-0 order-0 order-lg-1 marg-dl">
                            <button type="button" class="btn btn-success mx-1" v-on:click="restoreBuilding(data.id)">Відновити</button>
                            <button type="button" class="btn btn-danger mx-1" v-on:click="delTreshBuilding(data.id)">Видалити з бази</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import { VueDraggableNext } from 'vue-draggable-next'
import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox";
Fancybox.bind("[data-fancybox]", {
    on: {
        destroy: function () {

        },
    },
    keyboard: {
        Escape: "close",
    },
    infinite: false,

});
import AdmObjImages from './AdmObjImages.vue'
import moment from "moment";
//var moment = require('moment'); // require

export default {
    name: "AdmObjModal",
    components: {
        AdmObjImages,
        draggable: VueDraggableNext,
    },
    data() {
        return {
            enabled: false,
            dragging: false,
            id: null,
            delCheck: false,
            selfObj: true,
            districts: this.$store.state.dataDistricts,
            repairs: this.$store.state.dataRepairs,
            rescomplexes: this.$store.state.dataRescomplexes,
            types: this.$store.state.dataTypes,
            users: this.$store.state.dataUsers,
            materials: this.$store.state.dataMaterials,
            heatings: this.$store.state.dataHeatings,
            rooms:  this.$store.state.dataRooms,
            databuild:[],
            images:[],
            del_img: [],
            delImageCheck: false,

            hasErrorPrice: false,
            hasErrorRooms: false,
            hasErrorDistrict: false,
            hasErrorFloor: false,
            hasErrorFloormax: false,
            hasErrorType: false,
            hasErrorOwner: false,
            hasErrorOwnerPhone: false,
            hasErrorCustomRealtor: false,
            hasErrorCustomRealtorPhone: false,
            hasErrorDescription: false,
            hasErrorRealtor: false,

            data: {
                id: null,
                href: null,
                price: null,
                owner: '',
                owner_phone: '',
                address: null,
                floor: null,
                max_floor: null,
                repair_id: null,
                mark: null,
                squaring_total: null,
                squaring_kitchen: null,
                material: null,
                heating: null,
                description: '',
                description_front: '',
                note: '',
                district_id: null,
                type_id: null,
                rescomplexe_id: null,
                rooms: null,
                realtor_id: null,
                realtor: [],
                author_id: this.$store.state.loginId,
                department_id: null,
                custom_realtor: null,
                custom_realtor_phone: null,
                ex: false,
                nice: false,
                status: 0,
                whose: 0,
                changes: [],
            }
        }
    },
    methods: {
        async createBuilding() {
            //start animation
            this.$store.state.modalLoadind = true;
            try {
                const response = await this.axios.post('/api/buildings/create', this.data);
                this.resetError();
                this.$refs.refImages.imgStore(response.data.id);
                //alert('Добавлено');
                this.getBuildInfo(response.data.id);
                console.log(response.data.id);
                this.refreshList();
                this.selfCheck();
            } catch (err) {
                const {data, status} = err.response;
                if (status === 422) {
                    this.getError(data.errors);
                    this.$store.state.modalLoadind = false;
                }
            }

            //stop animation
  /*
            await this.axios.post('/api/buildings/create', this.data);
                .then((response) => {
                    console.log(response);
                    console.log(1)

                    this.resetError();
                    this.$refs.refImages.imgStore(response.data);
                    alert('Добавлено');
                    this.getBuildInfo(response.data);
                    this.refreshList();


                })
                .catch((error) => {

                    //this.getError(error.response.data.errors)
                    console.log(error);
                    console.log('lkjhyt')
                    //alert('qwedas');
                })
*/
        },
        updateBuilding() {
            this.$store.state.modalLoadind = true;
            this.axios
                .post('/api/buildings/edit', this.data)
                .then(() => {
                    this.resetError();
                    this.$refs.refImages.imgStore(this.data.id);
                    if (this.images.length !== 0) {
                        this.updateImages();
                    }
                    //alert('Збережено');
                    this.getBuildInfo(this.data.id);
                    this.refreshList();
                })
                .catch((error) => {
                    this.getError(error.response.data.errors);
                    this.$store.state.modalLoadind = false;
                })
        },
        updateImages() {
            this.axios
                .post('/api/images/edit', this.images)
                .then(() => {
                })
                .catch((error) => {
                    console.log(error.response.data.errors.floor);
                })
        },
        getError(error) {

            this.resetError();

            if (error.price) {
                this.hasErrorPrice = true;
                //alert("Некоректна ціна");
                //console.log(error.response.data.errors.price);
            }
            if (error.rooms) {
                this.hasErrorRooms = true;
            }
            if (error.district_id) {
                this.hasErrorDistrict = true;
            }
            if (error.floor) {
                this.hasErrorFloor = true;
            }
            if (error.max_floor) {
                this.hasErrorFloormax = true;
            }
            if (error.type_id) {
                this.hasErrorType = true;
            }
            if (error.owner) {
                this.hasErrorOwner = true;
            }
            if (error.owner_phone) {
                this.hasErrorOwnerPhone = true;
            }
            if (error.custom_realtor) {
                this.hasErrorCustomRealtor = true;
            }
            if (error.custom_realtor_phone) {
                this.hasErrorCustomRealtorPhone = true;
            }
            if (error.description) {
                this.hasErrorDescription = true;
            }
        },
        resetError(){
            this.hasErrorPrice = false;
            this.hasErrorRooms = false;
            this.hasErrorDistrict = false;
            this.hasErrorFloor = false;
            this.hasErrorFloormax = false;
            this.hasErrorType = false;
            this.hasErrorOwner = false;
            this.hasErrorOwnerPhone = false;
            this.hasErrorCustomRealtor = false;
            this.hasErrorCustomRealtorPhone = false;
            this.hasErrorDescription = false;

        },
        openGallery() {
            alert(123);
        },
        /*
        getDistricts() {
            this.axios
                .post('/api/districts')
                .then((response) => {
                    this.districts = response.data.data;
                })
                .catch((error) => {
                    console.log(error.response.data);
                })
        },
        getRepairs() {
            this.axios
                .post('/api/repairs')
                .then((response) => {
                    this.repairs = response.data.data;
                })
                .catch((error) => {
                    console.log(error.response.data);
                })
        },
        getTypes() {
            this.axios
                .post('/api/types')
                .then((response) => {
                    this.types = response.data.data;
                })
                .catch((error) => {
                    console.log(error.response.data);
                })
        },
        getRescomplexes() {
            this.axios
                .post('/api/rescomplexes')
                .then((response) => {
                    this.rescomplexes = response.data.data;
                })
                .catch((error) => {
                    console.log(error.response.data);
                })
        },
        getMeta() {
            this.axios
                .post('/api/meta')
                .then((response) => {
                    this.materials = response.data.meta.material;
                    this.heatings = response.data.meta.heating;
                    this.rooms = { "1" : 1, "2" : 2, "3" : 3, "4" :4, "5" : 5 };
                })
                .catch((error) => {
                    console.log(error.response.data.meta);
                })
        },
        userAgents() {
            this.axios
                .post('/api/users')
                .then((response) => {
                    this.$store.state.usersAgents = response.data.data;
                })
                .catch(err => {
                    console.log(err.response);
                })
        },
        */
        getBuildInfo(id) {
            this.resetError();
            this.axios
                .get('/api/buildings/'+id)
                .then((response) => {
                    this.databuild = response.data.data;
                    this.data = this.databuild;
                    this.images = response.data.images;
                    this.delCheck= false;
                    this.selfCheck();
                    this.$store.state.buildingId = id;
                })
                .catch((error) => {
                    console.log(error);
                })
        },
        delBuild(id) {
            this.axios
                .get('/api/buildings/trash/'+id)
                .then(() => {
                    alert("Об'єкт видалено");
                    document.getElementById('close').click();
                    this.refreshList();
                })
                .catch((error) => {
                    console.log(error);
                })
        },
        delImages() {
            if (this.del_img.length > 0) {
                this.$store.state.modalLoadind = true;
                this.axios
                    .post('/api/images/del', this.del_img)
                    .then(() => {
                        //alert("Зображення видалено");

                        this.getBuildInfo(this.data.id);
                        this.refreshList();
                        this.$store.state.modalLoadind = false;
                    })
                    .catch((error) => {
                        console.log(error);
                    })

            }
        },
        refreshList() {
            this.$emit('sendRefresh');
        },
        formatData(date) {
            moment.locale('uk');
            return moment(date, "YYYYMMDD, h:mm").format('DD MM YYYY, h:mm');
        },
        setDataDefault() {
            this.data.id = null;
            this.data.href = null;
            this.data.price = null;
            this.data.owner = '';
            this.data.owner_phone = '';
            this.data.address = null;
            this.data.floor = null;
            this.data.max_floor = null;
            this.data.repair_id = null;
            this.data.mark = null;
            this.data.squaring_total = null;
            this.data.squaring_kitchen = null;
            this.data.material = null;
            this.data.heating = null;
            this.data.description = '';
            this.data.description_front = '';
            this.data.note = '';
            this.data.type_id = null;
            this.data.district_id = null;
            this.data.rescomplexe_id = null;
            this.data.rooms = null;
            this.data.realtor_id = this.$store.state.loginId;
            this.data.department_id = null;
            this.data.district = null;
            this.data.type = null;
            this.data.repair = null;
            this.data.author_id = this.$store.state.loginId;
            this.data.ex = false;
            this.data.nice = false;
            this.data.custom_realtor = null;
            this.data.custom_realtor_phone = null;
            this.data.status = 0;
            this.data.whose = 0;
            this.data.changes = [];

            this.resetError();


            this.images = [];
        },
        selfCheck() {
            if (this.data.realtor_id == '98' || this.data.realtor_id == '99') {
                this.data.ex = false;
                this.selfObj = false;
            } else {
                this.selfObj = true;
            }
        },
        pushRealtor() {
            if (this.data.realtor_id !== '98' && this.data.realtor_id !== '99') {
                this.$store.state.dataUsers.forEach((value) => {
                    if (value.id === this.data.realtor_id) {
                        this.data.custom_realtor = value.name;
                        this.data.custom_realtor_phone = value.phone;
                    }
                });

            } else {
                this.data.custom_realtor = '';
                this.data.custom_realtor_phone = '';
            }
            this.selfCheck();
        },
        restoreBuilding(id) {
            this.axios
                .get('/api/buildings/restore/'+id)
                .then(() => {
                    alert("Об'єкт відновлено");
                    document.getElementById('close').click();
                    this.refreshList();
                })
                .catch((error) => {
                    console.log(error);
                })
        },
        delTreshBuilding(id) {
            this.axios
                .get('/api/buildings/delltrash/'+id)
                .then(() => {
                    alert("Об'єкт видалено з бази");
                    document.getElementById('close').click();
                    this.refreshList();
                })
                .catch((error) => {
                    console.log(error);
                })
        },


    },
    mounted() {
        //this.getDistricts();
        //this.getRepairs();
        //this.getTypes();
        //this.getRescomplexes();
        //this.getMeta();
        //this.userAgents();
        this.selfCheck();
    },
    beforeMount(){

    }

}


</script>

<style scoped>

</style>




