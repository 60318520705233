<template>
    <AdmHeader @dataDefaults="getDefault" />
    <AdmCliFilters @dataFilters="getFilters" />
    <AdmCliList ref="refList"/>
    <AdmFooter />
</template>

<script>

import AdmHeader from './AdmHeader.vue'
import AdmCliFilters from './AdmCliFilters.vue'
import AdmCliList from './AdmCliList.vue'
import AdmFooter from './AdmFooter.vue'

export default {
    name: "AdmCustomers",
    components: {
        AdmHeader,
        AdmCliFilters,
        AdmCliList,
        AdmFooter,
    },
    data() {
        return {

        }
    },
    methods: {
        getFilters(filters) {
            this.$refs.refList.getCustomers(filters);

        },
        getDefault() {
            this.$refs.refList.setDefault();
        },

    },
    mounted() {
        //this.checkLogin();

    }
}

</script>

<style scoped>

</style>
