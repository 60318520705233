<template>
    <div class="dropzone-previews">
        <div ref="dropzone" class="obj-images-add-form">
            Добавити зображення
        </div>
    </div>
</template>

<script>
import Dropzone from 'dropzone'
export default {
    name: "AdmObjImages",
    data() {
        return {
            dropzone: null,
        }
    },
    mounted() {
        this.dropzone = new Dropzone(this.$refs.dropzone, {
            url: "qweqwr",
            autoProcessQueue: false,
            addRemoveLinks: true,
            parallelUploads: 10,
            previewsContainer: '.dropzone-previews',
            acceptedFiles: 'image/*,application/pdf',
        })
    },
    methods: {
            imgStore(id) {

            const files = this.dropzone.getAcceptedFiles();
            const fd = new FormData();
            fd.append('id', id);

            files.forEach( file => {
                fd.append('images[]', file);
                this.dropzone.removeFile(file);
            })

            this.axios
                .post('/api/images/add', fd, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(() => {
                    this.dropzone.removeFile(fd);
                    this.$store.state.modalLoadind = false;
                })

                .catch((error) => {
                    console.log(error.response);
                    this.$store.state.modalLoadind = false;
                })
        },
    }
}
</script>

<style scoped>

</style>
