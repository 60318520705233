import { createApp } from 'vue'
import App from './App.vue'

import BootstrapVue3 from "bootstrap-vue-3";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import "./css/app.css";

import { router } from './router';
import store from "./store";

import axios from 'axios'
import VueAxios from 'vue-axios'

axios.defaults.baseURL = 'https://baza.org.ua/';
//axios.defaults.baseURL = 'http://new-app/';
//localStorage.setItem('x_xsrf_token', '1111111');

axios.defaults.withCredentials = true;


axios.interceptors.response.use({},err => {
    if (err.response.status === 401 || err.response.status === 419) {
        const token = localStorage.getItem('x_xsrf_token')
        if (token) {
            localStorage.removeItem('x_xsrf_token')
        }
        this.$router.push({ name: 'login' });
    }
    return Promise.reject(err);
})
/*
import FingerprintJS from '@fingerprintjs/fingerprintjs'

// Initialize an agent at application startup.
const fpPromise = FingerprintJS.load()

;(async () => {
    // Get the visitor identifier when you need it.
    const fp = await fpPromise
    const result = await fp.get()
    //console.log(result.visitorId)
})()
*/
const app = createApp(App);
app.use(BootstrapVue3);
app.use(VueAxios, axios);
app.use(store);
app.use(router);

app.mount("#app");


