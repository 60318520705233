<template>
    <AdmHeader @dataDefaults="getDefault" />
    <div class="container-fluid">
        <div id="content" class="mg-top-5">
            <div class="object-list">
                <div class="object-list-add-loading" v-show="loading">
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                </div>
                <div class="object-item" v-for="(user) in users"
                     :key="user.id"
                     v-show="list">

                    <div class="list-item--id">{{ user.id }}</div>
                    <div class="item-info" v-on:click="modalInfo(user.id)" data-bs-toggle="modal" data-bs-target="#Modal">

                        <div class="list-item--user-name">{{ user.name }}</div>
                        <div class="list-item--user-email">{{ user.email }}</div>
                        <div class="list-item--user-phone">{{ user.phone }}</div>
                        <div class="list-item--user-role">{{ user.role === 3 ? 'Ріелтор' : 'Адміністратор' }}</div>


                    </div>

                </div>
            </div>
        </div>
    </div>


    <AdmUserModal ref="refModal" @sendRefresh="getUsers" />
    <AdmFooter />
</template>

<script>

import AdmHeader from './AdmHeader.vue'
import AdmUserModal from './AdmUserModal.vue'
import AdmFooter from './AdmFooter.vue'

export default {
    name: "AdmUsers",
    components: {
        AdmHeader,
        AdmUserModal,
        AdmFooter,

    },
    data() {
        return {
            loading: false,
            list: true,
            users: [],
        }
    },
    methods: {

        modalInfo(id) {
            this.$refs.refModal.getUserInfo(id);
        },
        getDefault() {
            this.$refs.refModal.setDefault();
        },
        async getUsers() {
            this.loading = true;
            this.list = false;

            try {
                const response = await this.axios.post('/api/users');
                this.users = response.data.data;
            } catch (error) {
                console.log(error);
            }

            this.loading = false;
            this.list = true;
        },

        checkLogin() {
            this.axios
                .post('/api/user/get')
                .then((response) => {
                    this.loginData = response;
                    this.$store.state.loginData = response.data;
                    this.$store.state.loginId = response.data.id;
                })
                .catch(() => {
                })
        },
    },
    mounted() {
        //this.checkLogin();
        this.getUsers();
    }
}

</script>

<style scoped>

</style>
