<template>
    <AdmHeader @dataDefaults="getDefault" />
    <div class="container-fluid">
        <div id="content" class="mg-top-5">
            <div class="object-list">
                <div class="object-list-head">


                    <div class="list-item--check"></div>
                    <div class="item-info">
                        <div class="list-item--upd">
                            <div class="sort">
                                <input class="inp-radiobox-btn" type="radio" id="sort_u"
                                       v-model="this.$store.state.filterDataClient.sort2"
                                       value="created_at"
                                       @click="setSortDirection">
                                <label class="form-radiobox-label" for="sort_u">Оновл<span>{{arrow}}</span></label>
                            </div>
                        </div>
                        <div class="list-item--stage">Етап</div>
                        <div class="list-item--phone">Телефон</div>
                        <div class="list-item--name">Ім'я</div>
                        <div class="list-item--room">Кім</div>

                        <div class="list-item--price">
                            <div class="sort">
                                <input class="inp-radiobox-btn" type="radio" id="sort_p"
                                       v-model="this.$store.state.filterDataClient.sort2"
                                       value="price"
                                       @click="setSortDirection">
                                <label class="form-radiobox-label" for="sort_p">Ціна<span>{{arrow}}</span></label>
                            </div>
                        </div>
                        <div class="list-item--districts">Район</div>
                        <div class="list-item--creat">
                            <div class="sort">
                                <input class="inp-radiobox-btn" type="radio" id="sort_c"
                                       v-model="this.$store.state.filterDataClient.sort2"
                                       value="updated_at"
                                       @click="setSortDirection">
                                <label class="form-radiobox-label" for="sort_c">Створ<span>{{arrow}}</span></label>
                            </div>
                        </div>
                        <div class="list-item--descr_h">Опис</div>
                    </div>
                    <div class="list-item--id">ID</div>
                </div>

                <div class="object-list-add-loading" v-show="loading">
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                    <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
                </div>

                <div class="object-item" v-for="(customer) in customers"
                     :key="customer.id"
                     v-bind:class="{ linecolornice: customer.nice, linecolorex: customer.ex }"
                     v-show="list">

                    <div class="list-item--check" v-on:click.self.prevent>
                    </div>
                    <div class="item-info" v-on:click="modalInfo(customer.id)" data-bs-toggle="modal" data-bs-target="#Modal">
                        <div class="list-item--upd">{{ this.formatData(customer.updated_at) }}</div>
                        <div class="list-item--stage">{{ customer.stage_favourite ? 'У' : '' }} {{ customer.stage_adequate ? 'А' : '' }} {{ customer.stage_dreamer ? 'М' : '' }}</div>
                        <div class="list-item--phone">{{ customer.phone }}</div>
                        <div class="list-item--name">{{ customer.name }}</div>
                        <div class="list-item--room">{{ customer.rooms_str }}</div>
                        <div class="list-item--price">{{ customer.price }}</div>
                        <div class="list-item--districts"><span v-for="(district) in customer.districts" :key="district.id">{{ district.name }}&nbsp;</span></div>
                        <div class="list-item--creat">{{ this.formatData(customer.created_at) }}</div>
                        <div class="list-item--description">{{ customer.description }}</div>
                    </div>
                    <div class="list-item--id">{{ customer.id }}</div>
                </div>


            </div>

            <div class="object-list-add-loading" v-show="loadingScroll">
                <b-skeleton animation="wave" height="50px" width="100%"></b-skeleton>
            </div>
        </div>
    </div>


    <AdmCliModal ref="refModal" @sendRefresh="getTrashCustomers" />
    <AdmFooter />
</template>

<script>

import AdmHeader from './AdmHeader.vue'
import moment from "moment";
import AdmCliModal from './AdmCliModal.vue'
import AdmFooter from "@/components/AdmFooter";

export default {
    name: "AdmDeleteCustomers",
    components: {
        AdmHeader,
        AdmCliModal,
        AdmFooter,
    },
    data() {
        return {
            loading: false,
            list: true,
            loadingScroll: false,
            empty: false,
            customers:[],
            lineColor: 1,
            arrow : '🠅',
            dataId: {
                action: null,
                ids: [],
            },
            isActive: false,
            data: {
                filters: {
                    type: []
                },
                filter_results: 1,
                sort: 'price',
                direction: 'ASC',
                page: 1
            }
        }
    },
    methods: {
        getTrashCustomers() {

            this.loading = true;
            this.list = false;

            this.$store.state.filterDataClient.page = 1;
            this.axios
                .post('/api/customers/trash', this.$store.state.filterDataClient)
                .then((response) => {
                    this.customers = response.data.data;
                    document.addEventListener('scroll', this.handleScrollw);

                    if (response.data.data.length === 0) {
                        this.empty = true;
                    } else {
                        this.empty = false;
                    }
                    this.loading = false;
                    this.list = true;
                })
                .catch((error) => {
                    console.log(error);
                })
        },
        getTrashCustomersPlus() {
            this.loadingScroll = true;

            this.axios
                .post('/api/customers/trash', this.$store.state.filterDataClient)
                .then((response) => {
                    if (response.data.data.length > 0) {
                        response.data.data.forEach(element => this.customers.push(element));
                    } else {
                        this.loadingScroll = false;
                        this.empty = true;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })

        },
        handleScrollw () {
            if (this.empty === false) {
                if (window.scrollY+1 >= document.body.scrollHeight - window.innerHeight) {
                    this.$store.state.filterDataClient.page++;
                    this.getTrashCustomersPlus();
                }
            }
        },
        modalInfo(id) {
            this.$refs.refModal.getCustomerInfo(id);
        },
        getDefault() {
            this.$refs.refList.setDefault();
        },
        checkLogin() {
            this.axios
                .post('/api/user/get')
                .then((response) => {
                    this.loginData = response;
                    this.$store.state.loginData = response.data;
                    this.$store.state.loginId = response.data.id;
                })
                .catch(() => {
                })
        },
        formatData(date) {
            moment.locale('uk');
            return moment.utc(date).fromNow();
        },
        setSort() {
            //this.getBuildings();
        },
        setSortDirection(event) {
            if (this.$store.state.filterDataClient.sort === event.target.value) {
                this.$store.state.filterDataClient.sort = event.target.value
                if (this.$store.state.filterDataClient.direction === 'ASC') {
                    this.$store.state.filterDataClient.direction = 'DESC'
                    this.arrow = '🠇'
                } else {
                    this.$store.state.filterDataClient.direction = 'ASC'
                    this.arrow = '🠅'
                }
                this.getTrashCustomers();
            } else {
                this.$store.state.filterDataClient.sort = event.target.value
                this.$store.state.filterDataClient.direction = 'ASC'
                this.arrow = '🠅';
                this.getTrashCustomers();
            }
        },
    },
    mounted() {
        //this.checkLogin();
        this.getTrashCustomers();

    }
}

</script>

<style scoped>

</style>
