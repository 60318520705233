<template>
    <AdmHeader @dataDefaults="getDefault" />

</template>

<script>

import AdmHeader from './AdmHeader.vue'


export default {
    name: "AdmProfile",
    components: {
        AdmHeader,

    },
    data() {
        return {

        }
    },
    methods: {

        getDefault() {
            this.$refs.refList.setDefault();
        },
        checkLogin() {
            this.axios
                .post('/api/user/get')
                .then((response) => {
                    this.loginData = response;
                    this.$store.state.loginData = response.data;
                    this.$store.state.loginId = response.data.id;
                })
                .catch(() => {
                })
        },
    },
    mounted() {
        this.checkLogin();

    }
}

</script>

<style scoped>

</style>
